import React, { useState, useEffect } from "react";
import Select from 'react-select';
import Input from "../../reusable/Input/Input";
import Button from "../../reusable/Button/Button";
import { Checkbox, FormGroup } from '@mui/material';
import "./PersonalDataForm.scss";
import axios from "axios";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { FormControlLabel } from "@mui/material";
import { css } from "@emotion/css";

const PersonalDataForm = (props) => {

    const payment_types = [{ label: "Taxă lunară", value: "monthly" }, { label: "Altă plată", value: "other" }];

    const months = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"];

    const when_pay = [
        {
            label: "Prima zi a lunii",
            value: "first"
        },
        {
            label: "A 15-a zi a lunii",
            value: "middle"
        },
        {
            label: "Ultima zi a lunii",
            value: "last"
        }
    ]

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [teams, setTeams] = useState([]);
    const [team, setTeam] = useState("");
    const [players, setPlayers] = useState([]);
    const [child, setChild] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [paymentDetails, setPaymentDetails] = useState('');

    const [fixedAmountsPerTeams, setFixedAmountsPerTeams] = useState('');
    const [fixedAmount, setFixedAmount] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(null);

    const [monthsToPay, setMonthsToPay] = useState([
        // {
        //     label: "Iunie 2022",
        //     value: "6-2022"
        // },
        // {
        //     label: "Iulie 2022",
        //     value: "7-2022"
        // },
        // {
        //     label: "August 2022",
        //     value: "8-2022"
        // },
    ]);
    const [monthToPay, setMonthToPay] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const [dayOfPay, setDayOfPay] = useState("");

    const [message, setMessage] = useState("");

    const [error, setError] = useState("");

    const [styleFocused1, setStyleFocused1] = useState({ 'border-bottom': `1px solid grey` });
    const [styleFocused2, setStyleFocused2] = useState({ 'border-bottom': `1px solid grey` });
    const [styleFocused3, setStyleFocused3] = useState({ 'border-bottom': `1px solid grey` });


    useEffect(() => {
        setMonthsForSelect();
        getTeams();
        getTeamsTaxAmounts();
    }, [team]);

    const getTeams = () => {
        axios.get(`https://api.thefootballbrain.app/site/get_teams?id_club=${props.id_club}`)
            .then(res => {
                const teams_data = res.data;
                let teams = [];
                teams_data.forEach(elem => {
                    teams.push({ "label": elem.team_name, "value": elem.id_team });
                });
                setTeams(teams);
            })
            .catch(err => console.log(err));

    }

    const getTeamsTaxAmounts = () => {
        axios.get(`https://api.thefootballbrain.app/site/get_teams_tax_amount?id_club=${props.id_club}`)
            .then(res => setFixedAmountsPerTeams(res.data))
            .catch(err => console.log(err));
    }

    const getPlayers = (team) => {
        axios.get(`https://api.thefootballbrain.app/site/get_players_payments_site?id_team=${team.value}`)
            .then(res => {
                const players_data = res.data;
                let players = [];
                players_data.forEach(elem => {
                    players.push({ "label": elem.player_name.toUpperCase(), "value": elem.id_player });
                });
                setPlayers(players);
            })
            .catch(err => console.log(err));

    }

    const setMonthsForSelect = () => {
        let currentMonth = new Date().getMonth();
        let firstDate = new Date().setMonth(currentMonth - 6);
        let firstMonth = new Date(firstDate).getMonth();
        let firstYear = new Date(firstDate).getFullYear();
        let lastDate = new Date().setMonth(currentMonth + 6);
        let lastMonth = new Date(lastDate).getMonth();
        let lastYear = new Date(lastDate).getFullYear();
        let monthsSelect = [];
        if (firstYear == lastYear) {
            for (let i = firstMonth; i <= lastMonth; i++) {
                monthsSelect.push({
                    label: months[i] + " " + firstYear,
                    value: (i + 1) + "-" + firstYear
                })
            }
        } else {
            for (let i = firstMonth; i < 12; i++) {
                monthsSelect.push({
                    label: months[i] + " " + firstYear,
                    value: (i + 1) + "-" + firstYear
                })
            }
            for (let i = 0; i <= lastMonth; i++) {
                monthsSelect.push({
                    label: months[i] + " " + lastYear,
                    value: (i + 1) + "-" + lastYear
                })
            }
        }
        setMonthsToPay(monthsSelect);
    }

    function nextStep(e) {
        e.preventDefault();


        if (
            name != "" &&
            email != "" &&
            team != "" &&
            child != "" &&
            paymentType != "" &&
            paymentAmount != "" &&
            (checkbox ? dayOfPay != "" : true) &&
            (paymentDetails == "checkbox" ? monthToPay != "" : true)
        ) {
            props.dataAuth({
                name: name,
                email: email,
                id_team: team,
                id_child: child.value,
                payment_type: paymentType.value,
                payment_amount: paymentAmount,
                recurrent_payment: checkbox,
                day_of_pay: dayOfPay,
                description: message,
                payment_month: monthToPay != "" ? monthToPay.value.split("-")[0] : "",
                payment_year: monthToPay != "" ? monthToPay.value.split("-")[1] : "",
            });
        } else {
            setError("Toate câmpurile sunt obligatorii.");
        }
    }

    return (
        <div className="personal-data-form-container" style={{ display: props.toShow ? "block" : "none", color: props.config_options.contact_color }}>
            <div className="personal-data-form-title">
                Pas 1 din 2
            </div>
            <div className="personal-data-form-content">
                <form>
                    <div className="form-row">
                        <Input
                            style={styleFocused1}
                            name="name"
                            label="Nume și prenume"
                            value={name}
                            handleChange={(e) => {
                                setName(e.target.value);
                                setError("");
                            }
                            }
                            onFocus={() => {
                                setError("");
                                setStyleFocused1({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                            }}
                            onBlur={() => {
                                setStyleFocused1({ 'border-bottom': `1px solid grey` });
                            }}
                            halfWidth
                        />
                        <Input
                            style={styleFocused2}
                            name="email"
                            label="Email"
                            value={email}
                            handleChange={(e) => {
                                setEmail(e.target.value);
                                setError("");
                            }
                            }
                            onFocus={() => {
                                setError("");
                                setStyleFocused2({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                            }}
                            onBlur={() => {
                                setStyleFocused2({ 'border-bottom': `1px solid grey` });
                            }}
                            halfWidth
                        />
                    </div>
                    <div className="form-row custom-form-row">
                        <div className="group half-width">
                            <div className="select">
                                <label className="child-input-label" style={{ paddingLeft: "5px" }}>Echipa</label>
                                <Select className="input-select" name="echipa"
                                    label="Echipa"
                                    options={teams}
                                    onChange={(item) => {
                                        setTeam(item);
                                        getPlayers(item);
                                        setError("");
                                        setChild("");
                                        setFixedAmount(fixedAmountsPerTeams[item.value]);
                                        setPaymentAmount(fixedAmountsPerTeams[item.value]);
                                    }
                                    }
                                    placeholder={"Selectează..."}
                                    value={team}
                                    onFocus={() => setError("")}
                                    styles={{ marginBottom: "50px", width: "100%" }}
                                />
                            </div>
                        </div>
                        <div className="group half-width">
                            <div className="select">
                                <label className="child-input-label" style={{ paddingLeft: "5px" }}>Copil</label>
                                <Select className="input-select" name="child"
                                    label="Copil"
                                    options={players}
                                    onChange={(item) => {
                                        setChild(item);
                                        setError("");
                                    }
                                    }
                                    placeholder={"Selectează..."}
                                    value={child}
                                    onFocus={() => setError("")}
                                    styles={{ marginBottom: "50px", width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div
                            className="group no-margin-bottom half-width"
                            style={{
                                display: "flex",
                                gap: "25px"
                            }}
                        >
                            <div className="select">
                                <label className="child-input-label" style={{ paddingLeft: "5px" }}>Tip plată</label>
                                <Select className="input-select" name="payment"
                                    label="Tip plata"
                                    options={payment_types}
                                    onChange={(item) => {
                                        setPaymentType(item)
                                        setError("");
                                        if (item.value == 'monthly') {
                                            setPaymentAmount(fixedAmount);
                                            setPaymentDetails('checkbox');
                                            setMessage('');
                                        } else {
                                            setPaymentAmount('');
                                            setPaymentDetails('description');
                                            setCheckbox(false);
                                            setMonthToPay("");
                                        }
                                    }
                                    }
                                    placeholder={"Selectează..."}
                                    value={paymentType}
                                    onFocus={() => setError("")}
                                    styles={{ marginBottom: "50px", width: "100%" }}
                                />
                            </div>
                            {paymentDetails.length > 0 && (
                                <div className="quarter-width align-corner-left">
                                    <label className="child-input-label">Sumă</label>
                                    <input
                                        className="form-input"
                                        value={paymentAmount}
                                        onChange={(event) => {
                                            setPaymentAmount(event.target.value);
                                        }}
                                        onFocus={() => {
                                            setError("");
                                            setStyleFocused3({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                                        }}
                                        onBlur={() => {
                                            setStyleFocused3({ 'border-bottom': `1px solid grey` });
                                        }}
                                        style={styleFocused3}
                                    />
                                    <label className="child-input-label">lei</label>
                                </div>
                            )}
                        </div>
                        {paymentDetails == 'checkbox' &&
                            <div className="group no-margin-bottom half-width">
                                <div className="select">
                                    <label className="child-input-label" style={{ paddingLeft: "5px" }}>Lună plată</label>
                                    <Select className="input-select" name="payment"
                                        label="Lună plată"
                                        options={monthsToPay}
                                        onChange={(item) => {
                                            setMonthToPay(item)
                                            setError("");
                                        }}
                                        placeholder={"Selectează..."}
                                        value={monthToPay}
                                        onFocus={() => setError("")}
                                        styles={{ marginBottom: "50px", width: "100%" }}
                                    />
                                </div>
                            </div>}
                    </div>
                    {paymentAmount != "" && paymentDetails.length > 0 && props.feePassedToCustomer == 1 &&
                        <div className="form-row commision-message">
                            {`Comisonul pentru această plată este de ${((paymentAmount * 0.014 + 1).toFixed(2)).toLocaleString('ro')} RON.`}
                        </div>
                    }
                    {paymentDetails != "" && (
                        <div className="form-row custom-form-row half-width top-margin">
                            {paymentDetails == 'checkbox' && (
                                <div className="group" style={{ marginBottom: "15px" }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkbox}
                                                    onChange={(item) => {
                                                        setCheckbox(item.target.checked);
                                                        setError("");
                                                        if (item.target.checked == false) {
                                                            setDayOfPay("");
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Plată lunară recurentă"
                                        />
                                    </FormGroup>
                                </div>
                            )}
                            {paymentDetails == 'description' && (
                                <Input
                                    label="Descriere"
                                    onChange={(event) => {
                                        setMessage(event.target.value);
                                    }}
                                    value={message}
                                />
                            )}
                        </div>
                    )}
                    {checkbox && (
                        <div className="form-row">
                            <div className="group half-width">
                                <div className="select">
                                    <label className="child-input-label" style={{ paddingLeft: "5px" }}>Dată plată recurentă</label>
                                    <Select className="input-select" name="data_plata"
                                        label="Dată plată recurentă"
                                        options={when_pay}
                                        onChange={(item) => {
                                            setDayOfPay(item);
                                            setError("");
                                        }
                                        }
                                        placeholder={"Selectează..."}
                                        value={dayOfPay}
                                        onFocus={() => setError("")}
                                        styles={{ marginBottom: "50px", width: "100%" }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {error !== "" && <div className="error-row">{error}</div>}
                    <div className="button-row" style={{ marginTop: "60px" }}>
                        <Button type="submit" onClick={nextStep}
                            theme={props.config_options.id_theme}
                            color={props.config_options.buttons_text_color}
                            backgroundColor={props.config_options.buttons_color}
                        // className={css`
                        // &:hover {
                        //     color: ${props.config_options.buttons_color}!important;
                        //     background-color: ${props.config_options
                        //         .buttons_text_color}!important;
                        //     border-radius: ${props.config_options.id_theme == 2
                        //         ? "12px 12px 0px 0px"
                        //         : "0px"}!important;
                        // `}
                        >
                            URMĂTORUL PAS
                            <ArrowRight
                                fill={props.config_options.buttons_text_color}
                                className={`send-icon rigth`} />
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PersonalDataForm;
