import React from "react";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";
import Button from "../../reusable/Button/Button";
import "./StaffMembers.scss";

export default function StaffIndividual(props) {
  const history = useHistory();
  function addDefaultSrc(event) {
    event.target.src = `https://api.thefootballbrain.app/uploads/staff_members/placeholder_antrenor.png`;
  }

  return (
    <div className="staff-member-card-3" style={{ borderRadius: 30 }}>
      <div className="staff-member-image-container">
        <img
          src={`https://api.thefootballbrain.app/uploads/staff_members/${props.item.id_staff_member}`}
          onError={addDefaultSrc}
          alt="staff_image"
        />
      </div>
      <div
        className="staff-member-name notranslate"
        style={{ color: props.config_options.news_title_color }}
      >
        {props.item.staff_member_name}
      </div>
      <div
        className="staff-member-label"
        style={{
          color: props.config_options.buttons_color,
        }}
      >
        {props.item.staff_member_role}
      </div>
      <div className="see-more-button-staff-3">
        <Button
          className="arrow-button-3"
          onClick={() =>
            history.push(
              `/staff/${props.item.staff_member_name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9 \-]/g, "")
                .replaceAll(" ", "-")}-${props.item.id_staff_member}`
            )
          }
          theme={props.config_options.id_theme}
          color={props.config_options.buttons_text_color}
          backgroundColor={props.config_options.buttons_color}
        >
          Vezi profilul{" "}
          <ArrowRight
            fill={props.config_options.buttons_text_color}
            className="discover-more-button-icon-3"
          />
        </Button>
      </div>
    </div>
  );
}
