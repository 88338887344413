import React from "react";
import "./Groups.scss";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";

export default function Groups(props) {
  const history = useHistory();

  return (
    <div
      className="group-element-container-3"
      style={{ borderRadius: 30, borderColor: "white" }}
    >
      <div
        className="group-name notranslate"
        style={{ color: props.config_options.groups_name_color }}
      >
        <span style={{ color: "#01b1e4" }}>Grupa: </span>
        {props.team.team_name}
      </div>
      <div
        className="group-coach"
        style={{ color: props.config_options.groups_coach_color }}
      >
        <div>
          <div className="coach" style={{ color: "#01b1e4" }}>
            Antrenori:
          </div>
          <div className="coach-name notranslate">{props.team.coach_name}</div>
        </div>
      </div>
      <div className="discover-more-button">
        <button
          className="arrow-button"
          onClick={() =>
            history.push(
              `/groups/${props.team.team_name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9 \-]/g, "")
                .replaceAll(" ", "-")}-${props.team.id_team}`
            )
          }
          theme={props.config_options.id_theme}
          color={props.config_options.buttons_text_color}
          backgroundColor={props.config_options.buttons_color}
        >
          Află mai multe{" "}
          {/* <ArrowRightIcon
            fill={props.config_options.buttons_text_color}
            className="discover-more-button-icon"
          /> */}
        </button>
      </div>
    </div>
  );
}
