import React, { useState, useEffect } from "react";
// import "./OnlinePaymentsSuccess.scss"
import axios from "axios";
import Loader from "react-loader-spinner";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";

export default function OnlinePaymentsSuccess(props) {
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const setupIntent = queryParams.get("payment_intent");
  const setupIntentClientSecret = queryParams.get(
    "payment_intent_client_secret"
  );
  const redirectStatus = queryParams.get("redirect_status");

  const months = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];

  const [paymentDetails, setPaymentDetails] = useState("");

  useEffect(() => {
    if (
      setupIntent !== "" &&
      setupIntentClientSecret !== "" &&
      redirectStatus == "succeeded"
    ) {
      const data = {
        payment_intent: setupIntent,
        payment_intent_client_secret: setupIntentClientSecret,
        redirect_status: redirectStatus,
      };
      axios
        .post(
          "https://api.thefootballbrain.app/payment/capture_donation_intent",
          data
        )
        .then((res) => {
          setPaymentDetails(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      {paymentDetails != "" ? (
        <>
          {redirectStatus == "succeeded" ? (
            <div className="donations-success-message">
              {`Donația a fost efectuată cu succes!`}
              {/* <br></br>
              {`Detalii plată:`}
              <br></br>
              {`- Denumire club: ${paymentDetails.club_name}`}
              <br></br>
              {`- Denumire echipă: ${paymentDetails.team_name}`}
              <br></br>
              {`- Nume jucător: ${paymentDetails.player_name}`}
              <br></br>
              {`- Tip plată: ${
                { monthly: "Taxă lunară", other: "Altă plată" }[
                  paymentDetails.payment_type
                ]
              }`}
              <br></br>
              {paymentDetails.payment_type == "other"
                ? `- Descriere: ${paymentDetails.payment_description}`
                : `- Plată pentru: ${
                    months[paymentDetails.payment_month - 1] +
                    " " +
                    paymentDetails.payment_year
                  }`}
              <br></br>
              {`- Suma: ${paymentDetails.payment_amount + " RON"}`}
              <br></br>
              {props.feePassedToCustomer == 1 &&
                `- Comision: ${(paymentDetails.payment_amount * 0.014 + 1)
                  .toFixed(2)
                  .toLocaleString("ro")} RON`}
              {props.feePassedToCustomer == 1 && <br></br>}
              {paymentDetails.payment_type == "monthly" &&
                `- Recurența: ${
                  paymentDetails.is_recurrent == 0
                    ? "nu a fost setată"
                    : `a fost setată pentru ${
                        {
                          first: "prima zi a lunii",
                          middle: "a 15-a zi a lunii",
                          last: "ultima zi a lunii",
                        }[paymentDetails.recurrent_date]
                      } începând cu ${
                        paymentDetails.payment_month == 12
                          ? months[0].toLowerCase() +
                            " " +
                            (parseInt(paymentDetails.payment_year) + 1)
                          : months[paymentDetails.payment_month].toLowerCase() +
                            " " +
                            paymentDetails.payment_year
                      }`
                }`} */}
            </div>
          ) : (
            <div className="onlinepayments-success-message">
              A intervenit o eroare la efectuarea donației. Încearcă din nou!
            </div>
          )}
          <div className="button-row another-donation-row" >
            <Button
              type="submit"
              onClick={() => {
                history.push(`/donations`);
              }}
              theme={props.config_options.id_theme}
              color={props.config_options.buttons_text_color}
              backgroundColor={props.config_options.buttons_color}
            >
              {redirectStatus == "succeeded"
                ? "ALTĂ DONAȚIE"
                : "ÎNCEARCĂ DIN NOU"}
              <ArrowRight
                fill={props.config_options.buttons_text_color}
                className="send-icon rigth"
              />
            </Button>
          </div>
        </>
      ) : (
        <div className="loader-container">
          <Loader
            type="ThreeDots"
            color={props.config_options.main_color}
            height={80}
            width={80}
          />
        </div>
      )}
    </>
  );
}
