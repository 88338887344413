import React from "react";
import "./Input.scss";

const Input = ({ handleChange, label, ...otherInputProps }) => (
  <div
    className={
      otherInputProps.halfWidth ? "group-3 half-width" : "group-3 full-width"
    }
  >
    <input
      className="form-input-3"
      onChange={handleChange}
      {...otherInputProps}
    />
    {label ? (
      <label
        className={`${otherInputProps.value.length ? "shrink" : ""
          } form-input-label-3`}
      >
        {label}
      </label>
    ) : null}
  </div>
);

export default Input;
