import React, { useState, useEffect } from "react";
import "./Donations.scss";
import axios from "axios";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import Select from "react-select";
import Loader from "react-loader-spinner";
import Input from "../../reusable/Input/Input";
import Button from "../../reusable/Button/Button";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/svg/arrow-left.svg";
import SetupForm from "./SetupForm";

export default function Donations(props) {
  const [image, setImage] = useState("");
  const [content, setContent] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [dayOfPay, setDayOfPay] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [group, setGroup] = useState("");
  const [step, setStep] = useState("1");
  const [error, setError] = useState("");
  const [invalidEmail, setInvalidEmailError] = useState("");
  const [options, setOptions] = useState({});

  const [donationType, setDonationType] = useState("");
  const [donationTypes, setDonationTypes] = useState([]);

  const [buttons, setButtons] = useState([]);

  const recurringPayment = [
    {
      label: "Prima zi a lunii",
      value: "first",
    },
    {
      label: "A 15-a zi a lunii",
      value: "middle",
    },
    {
      label: "Ultima zi a lunii",
      value: "last",
    },
  ];

  const [styleFocused1, setStyleFocused1] = useState({
    "border-bottom": `1px solid grey`,
  });
  const [styleFocused2, setStyleFocused2] = useState({
    "border-bottom": `1px solid grey`,
  });

  // const stripePromise = loadStripe("pk_live_51K8lBkEYxczY06hy6Ev5rMfnqJ1CDzcyio8hWrYHeWuKY8pgC9iX1YVoFdDZoTfvgdWVG7U0KCCdwZpPyGUhGYmy00YdQRRFrS", {
  const stripePromise = loadStripe(
    "pk_test_51K8lBkEYxczY06hyqcyISPh4Itn3ehH1ERRj6Igyfg5d6SNxCX8DNXBKBlEOh86zEit0JX4KcinUNHu1I0K3fm4r00HyB1WKvZ",
    {
      locale: `${props.lang}` === "ro" ? "ro" : "en",
    }
  );

  useEffect(() => {
    axios
      .get(`payment/get_donation_content?id_club=${props.id_club}`)
      .then((res) => {
        setImage(res.data.donation_content.donation_img);
        setContent(res.data.donation_content.donation_text);
        setButtons(res.data.donation_amounts);
        setSelectedAmount(res.data.implicit_donation);
        setDonationTypes(res.data.donations_type);
        setAmount(res.data.implicit_donation.label);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, [props.id_club]);

  const handleClick = (e) => {
    setSelectedAmount(e);
    if (e.value < 0) {
      setAmount("");
    } else {
      setAmount(e.label);
    }
  };

  const handleOnKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    setInvalidEmailError("Adresa de email este invalidă!");
    return false;
  };

  const getClientIntent = () => {
    if (
      fullName != "" &&
      email != "" &&
      amount != "" &&
      donationType != "" &&
      (checkbox ? dayOfPay != "" : true)
    ) {
      if (validateEmail(email)) {
        const payload = {
          name: fullName,
          email: email,
          id_donation_type: donationType.value,
          amount: amount,
          is_recurrent: { true: "1", false: "0" }[checkbox],
          id_club: props.id_club,
          club_name: props.club_name,
        };
        if (checkbox) {
          payload.recurrent_date = dayOfPay.value;
        }

        axios
          .post(
            "https://api.thefootballbrain.app/payment/generate_donation_intent",
            payload
          )
          .then((res) => {
            setOptions({
              clientSecret: res.data,
              appearance: {
                /*...*/
              },
            });
            setStep("2");
          })
          .catch((err) => console.log(err));
      }
    } else {
      setError("Toate câmpurile sunt obligatorii.");
    }
  };

  return (
    <div>
      {step == 2 ? (
        <div className="donations-page">
          <Elements stripe={stripePromise} options={options}>
            <SetupForm
              clientSecret={options.clientSecret}
              onClickBack={() => setStep("1")}
              toShow={loaded}
              config_options={props.config_options}
              lang={props.lang}
              urlRedirect={props.urlRedirectPayment}
            />
          </Elements>
        </div>
      ) : (
        <div className="donation-container">
          <div
            className="donation-title"
            style={{ color: props.config_options.section_title_color }}
          >
            Fă o donație
          </div>
          <div className="donation-section">
            <div className="contact-form-container sticky-donation">
              <div
                className="section-title subtitle-font"
                style={{
                  color: props.config_options.section_title_color,
                  // marginBottom: "20px",
                }}
              >
                Datele tale
              </div>
              <div className="form-content">
                <div className="form-row">
                  <Input
                    name="full_name"
                    label="Nume și prenume"
                    value={fullName}
                    handleChange={(e) => {
                      setFullName(e.target.value);
                    }}
                    style={styleFocused1}
                    onFocus={() => {
                      setStyleFocused1({
                        "border-bottom": `1px solid ${props.config_options.buttons_color}`,
                      });
                      setError("");
                    }}
                    onBlur={() => {
                      setStyleFocused1({ "border-bottom": `1px solid grey` });
                    }}
                    fullWidth
                  />

                  <Input
                    name="email"
                    label="Email"
                    value={email}
                    handleChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={styleFocused2}
                    onFocus={() => {
                      setStyleFocused2({
                        "border-bottom": `1px solid ${props.config_options.buttons_color}`,
                      });
                      setError("");
                      setInvalidEmailError("");
                    }}
                    onBlur={() => {
                      setStyleFocused2({ "border-bottom": `1px solid grey` });
                    }}
                    fullWidth
                  />
                </div>

                <div className="form-row"></div>

                <div className="select">
                  <div
                    className="section-title subtitle-font"
                    style={{ color: props.config_options.section_title_color }}
                  >
                    Donează pentru...
                  </div>

                  <Select
                    className="input-select select-donations"
                    name="echipa"
                    label="Echipa"
                    options={donationTypes}
                    onChange={(item) => {
                      setDonationType(item);
                    }}
                    placeholder={"Selectează..."}
                    value={donationType}
                    onFocus={() => {
                      setError("");
                    }}
                    styles={{ marginBottom: "50px", width: "100%" }}
                  />
                </div>
              </div>

              <div
                className="section-title subtitle-font select-amount"
                style={{
                  color: props.config_options.section_title_color,
                }}
              >
                Alege suma
              </div>

              <div className="button-row amount-button">
                {buttons.map((button, index) => {
                  return (
                    <Button
                      key={index}
                      onClick={() => handleClick(button)}
                      theme={props.config_options.id_theme}
                      color={
                        selectedAmount.value == button.value
                          ? "#ffffff"
                          : props.config_options.buttons_color
                      }
                      backgroundColor={
                        selectedAmount.value == button.value
                          ? props.config_options.buttons_color
                          : "#F0F0F0"
                      }
                    >
                      {button.label + (button.value < 0 ? "" : " lei")}
                    </Button>
                  );
                })}
              </div>

              {selectedAmount.value == -1 && (
                <div className="form-row input-amount">
                  <Input
                    name="amount"
                    label="Introdu suma"
                    value={amount}
                    onKeyPress={(e) => handleOnKeyPress(e)}
                    autoComplete="off"
                    handleChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    style={styleFocused2}
                    onFocus={() => {
                      setStyleFocused2({
                        "border-bottom": `1px solid ${props.config_options.buttons_color}`,
                      });
                      setError("");
                    }}
                    onBlur={() => {
                      setStyleFocused2({ "border-bottom": `1px solid grey` });
                    }}
                    fullWidth
                  />
                </div>
              )}

              <div className="half-width top-margin">
                <div className="group checkbox">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkbox}
                          onChange={(item) => {
                            setCheckbox(item.target.checked);
                            // setError("");
                            // if (item.target.checked == false) {
                            //   setDayOfPay("");
                            // }
                          }}
                        />
                      }
                      label="Donație recurentă"
                    />
                  </FormGroup>
                </div>
              </div>

              {checkbox && (
                <div className="recurring-row">
                  <div className="group">
                    <div className="select">
                      <Select
                        className="input-select select-donations"
                        name="data_plata"
                        label="Dată plată recurentă"
                        options={recurringPayment}
                        onChange={(item) => {
                          setDayOfPay(item);
                          // setError("");
                        }}
                        placeholder={"Alege data donației recurente"}
                        value={dayOfPay}
                        onFocus={() => {
                          setError("");
                        }}
                        styles={{
                          width: "100%",
                          color: props.config_options.buttons_color,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {error != "" && <div className="error-row">{error}</div>}

              {invalidEmail != "" && (
                <div className="error-row">{invalidEmail}</div>
              )}

              <div className="send-button">
                <Button
                  style={{
                    width: "100%",
                    height: "55px",
                  }}
                  onClick={() => {
                    getClientIntent();
                  }}
                  theme={props.config_options.id_theme}
                  color={props.config_options.buttons_text_color}
                  backgroundColor={props.config_options.buttons_color}
                >
                  TRIMITE
                </Button>
              </div>
            </div>

            <div className="description-container">
              <div className="donation-photo photo-desc">
                <img
                  src={image}
                  alt="article_image"
                  style={{
                    borderRadius:
                      props.config_options.id_theme == 2 ? "15px" : "0",
                    width: "inherit",
                  }}
                />
              </div>
              <div
                className="donation-text-container"
                style={{ color: props.config_options.article_subtitle_color }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {/* // {content} */}
              {/* <div
                  className="donation-subtitle"
                  >
                  {" "}
                  Lorem Ipsum dolor
                  </div>
                  <div
                  className="donation-paragraph"
                  style={{
                    color: props.config_options.article_subtitle_color,
                  }}
                  >
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidata
                  </div>
                  
                  <div
                  className="donation-subtitle"
                  style={{ color: props.config_options.article_subtitle_color }}
                  >
                  {" "}
                  Lorem Ipsum dolor
                  </div>
                  <div
                  className="donation-paragraph"
                  style={{
                    color: props.config_options.article_subtitle_color,
                  }}
                  >
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit
                  </div>
                  <div
                  className="donation-paragraph"
                  style={{
                    color: props.config_options.article_subtitle_color,
                  }}
                  >
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit
                </div> */}
              {/* // </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
