import React from "react";
import "./Button.scss";

const Button = ({ className, children, ...otherProps }) => (
  <button
    className={`custom-button-3 ${className}`}
    {...otherProps}
    style={{
      borderRadius: 10,
      backgroundColor: otherProps.backgroundColor,
      color: otherProps.color,
      ...otherProps.style,
    }}
  >
    {children}
  </button>
);

export default Button;
