import React, { useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./OnlinePayments.scss"
import axios from "axios";
import Loader from "react-loader-spinner";
import "../Competitions/Competitions.scss";
import SetupForm from "./SetupForm";
import PersonalDataForm from "./PersonalDataForm";

export default function OnlinePayments(props) {

    const stripePromise = loadStripe("pk_live_51K8lBkEYxczY06hy6Ev5rMfnqJ1CDzcyio8hWrYHeWuKY8pgC9iX1YVoFdDZoTfvgdWVG7U0KCCdwZpPyGUhGYmy00YdQRRFrS", {
    // const stripePromise = loadStripe("pk_test_51K8lBkEYxczY06hyqcyISPh4Itn3ehH1ERRj6Igyfg5d6SNxCX8DNXBKBlEOh86zEit0JX4KcinUNHu1I0K3fm4r00HyB1WKvZ", {
        locale: `${props.lang}` === 'ro' ? 'ro' : 'en'
    });

    const [showFirstStep, setShowFirstStep] = useState(true);
    const [showSecondStep, setShowSecondStep] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [options, setOptions] = useState({});

    // const [options, setOptions] = useState({
    //     clientSecret:
    //         "seti_1LBcrlEYxczY06hyaHJatj7a_secret_LtPhMpgdTn6oofAl5AOX9NvfdTDVAEk",
    //     appearance: {
    //         /*...*/
    //     }
    // });

    function toSecondStep() {
        setShowFirstStep(false);
        setShowSecondStep(true);
        setTimeout(() => {
            setLoaded(true);
        }, 2500);
    }

    function backToFirstStep() {
        setShowFirstStep(true);
        setShowSecondStep(false);
        setLoaded(false);
    }

    function pull_data(data) {
        const payload = {
            name: data.name,
            email: data.email,
            id_club: props.id_club,
            club_name: props.club_name,
            id_team: data.id_team.value,
            team_name: data.id_team.label,
            id_child: data.id_child,
            payment_type: data.payment_type,
            payment_amount: data.payment_amount,
            recurrent_payment: {
                true: "1",
                false: "0"
            }[data.recurrent_payment],
            description: data.description,
            day_of_pay: data.day_of_pay.value,
            payment_month: data.payment_month,
            payment_year: data.payment_year,
        };
        axios
            .post("https://api.thefootballbrain.app/payment/generate_payment_intent", payload)
            .then((res) => {
                setOptions({
                    clientSecret:
                        res.data,
                    appearance: {
                        /*...*/
                    }
                });
                toSecondStep();
            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="onlinepayments-page-container" style={{ color: props.config_options.contact_color }}>
            <div className="onlinepayments-title" >
                Plata online
            </div>
            <PersonalDataForm
                onClick={toSecondStep}
                dataAuth={pull_data}
                toShow={showFirstStep}
                id_club={props.id_club}
                config_options={props.config_options}
                lang={props.lang}
                feePassedToCustomer={props.feePassedToCustomer}
            />
            {showSecondStep ?
                (<div>
                    <div className="loader-container" style={{ display: !loaded ? "block" : "none" }}>
                        <Loader type="ThreeDots" color={props.config_options.main_color} height={80} width={80} lang={props.lang} />
                    </div>
                    <div>
                        <Elements stripe={stripePromise} options={options} >
                            <SetupForm clientSecret={options.clientSecret} onClickBack={backToFirstStep} toShow={loaded} config_options={props.config_options} lang={props.lang} urlRedirect={props.urlRedirectPayment} />
                        </Elements>
                    </div>
                </div>
                )
                : null}
        </div>
    );
}
