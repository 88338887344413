import React from "react";
import "./App.css";
import axios from "axios";
import { doGTranslate } from "./assets/translate-script/translateScript";
import $ from "jquery";
import Navigation from "./Navigation";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_club: "",
      club_name: "",
      homepage_description: "",
      main_details_received: false,
      theme: "",
      main_color: "",
      show_online_payments: "",
      show_only_online_payments: "",
      fee_passed_to_customer: "",
      show_donations: "",

      footer_text_color: "",
      config_options: {},
      lang: "ro",
      url_redirect_payment: "",
      multi_lang: "0",
    };
    // this.sponsorsRef = React.createRef();
    // this.contactRef = React.createRef();
  }

  // scrollToSponsors = () =>
  //   this.sponsorsRef.scrollIntoView({ behavior: "smooth", block: "start" });

  // scrollToContact = () =>
  //   this.contactRef.scrollIntoView({ behavior: "smooth", block: "start" });

  componentDidMount = () => {
    // DEV
    const url = "premieriasi.tfb.ai";
    // const url = window.location.host.includes("localhost")
    //   ? "premieriasi.tfb.ai"
    //   : window.location.host;
    // const url = window.location.host.includes("localhost") ? "steauamagica.ro" : window.location.host;
    // const url = window.location.host.includes("localhost") ? "startsuceava.tfb.ai" : window.location.host;
    const url_redirect_payment = window.location.host.includes("localhost")
      ? "localhost:3000"
      : window.location.host;
    this.setState({
      url_redirect_payment: url_redirect_payment,
    });
    // const url = "steauamagica.ro";
    // const url = "cityfciasi.ro";
    // const url = "getodacii.tfb.ai";
    // const url = "academiadidi.ro";
    // const url = "viitorularad.tfb.ai";
    // const url = "csfcpietricica.ro";
    // const url = "startsuceava.tfb.ai";
    //premieriasi.rfb.ai
    // LIVE
    // const url = window.location.host;

    axios.get(`site/get_main_site_details?url=${url}`).then((res) => {
      this.setState({
        id_club: res.data.id_club,
        club_name: res.data.club_name,
        homepage_description: res.data.description,
        main_details_received: true,
        theme: res.data.theme,
        main_color: res.data.main_color,
        config_options: res.data.config_options,
        show_online_payments: res.data.show_online_payments,
        show_only_online_payments: res.data.show_only_online_payments,
        fee_passed_to_customer: res.data.fee_passed_to_customer,
        multi_lang: res.data.multi_lang,
        show_donations: res.data.show_donations,
      });
      const favicon = document.getElementById("favicon");
      favicon.href = `https://api.thefootballbrain.app/uploads/club_logos/${res.data.id_club}.png`;
    });

    var c = document.cookie.split("; "),
      cookies = {},
      i,
      C;

    for (i = c.length - 1; i >= 0; i--) {
      C = c[i].split("=");
      cookies[C[0]] = C[1];
    }
    let lang;
    if (cookies["googtrans"] == undefined) {
      lang = "ro";
    } else {
      lang = cookies["googtrans"].split("/")[2];
    }
    this.setState({
      lang: lang,
    });
  };

  setLang = (event) => {
    this.setState({ lang: event.value });
  };

  render() {
    return (
      <>
        {this.state.multi_lang == 1 && (
          <>
            <select
              onChange={(event) => doGTranslate(event.target.value)}
              className="notranslate lang-select"
              id="lang-select"
            >
              <option
                value="ro|ro"
                selected={this.state.lang == "ro" ? true : false}
              >
                RO
              </option>
              <option
                value="ro|de"
                selected={this.state.lang == "de" ? true : false}
              >
                DE
              </option>
            </select>
            <img
              className="lang-flag"
              id="ro-lang"
              src="https://api.thefootballbrain.app/uploads/site/lang_flags/ro.png"
              style={{ display: this.state.lang == "ro" ? "block" : "none" }}
            ></img>
            <img
              className="lang-flag"
              id="de-lang"
              src="https://api.thefootballbrain.app/uploads/site/lang_flags/de.png"
              style={{ display: this.state.lang == "de" ? "block" : "none" }}
            ></img>
          </>
        )}
        <div id="google_translate_element2">
          {this.state.theme != "" && (
            <Navigation
              theme={this.state.theme}
              id_club={this.state.id_club}
              club_name={this.state.club_name}
              homepage_description={this.state.homepage_description}
              main_details_received={this.state.main_details_received}
              main_color={this.state.main_color}
              config_options={this.state.config_options}
              show_online_payments={this.state.show_online_payments}
              show_only_online_payments={this.state.show_only_online_payments}
              fee_passed_to_customer={this.state.fee_passed_to_customer}
              show_donations={this.state.show_donations}
              multi_lang={this.state.multi_lang}
              lang={this.state.lang}
              setLang={this.setLang}
            />
          )}
        </div>
      </>
    );
  }
}

export default App;
