import React from "react";
import Player from "./Player";
import StaffMember from "./StaffMember";
import "./Team.scss";
import GroupElement from "../../sections/Groups/GroupElement";
import { ReactComponent as LeftArrow } from "../../../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../../../../assets/icons/svg/arrow-right.svg";
import { css } from "@emotion/css";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";
import Loader from "react-loader-spinner";
import { data } from "jquery";
import { Redirect } from "react-router-dom";

const menuOptions = [
  { value: "all", text: "Toți jucătorii" },
  { value: "goalkeeper", text: "Portari" },
  { value: "defender", text: "Fundași" },
  { value: "midfielder", text: "Mijlocași" },
  { value: "forward", text: "Atacanți" },
];

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "all",
      activeIndex: 0,
      players: [],
      staffMembers: [],
      otherTeams: [],
      loaded: false,
      team_name: "",
      width: 0,
      has_access: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount = () => {
    this.hasAccessToGroup();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ loaded: false }, this.getPlayers);
    }
  };

  hasAccessToGroup = () => {
    const pathTeamName = this.props.match.params.id.split("-");
    const teamId = pathTeamName.slice(-1)[0];
    axios
      .post("site/has_access_to_group", {
        id_club: this.props.id_club,
        id_team: teamId,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data == 0) {
          this.setState({
            has_access: false,
          });
        } else {
          this.getPlayers();
        }
      });
  };

  getPlayers = () => {
    const pathTeamName = this.props.match.params.id.split("-");
    const teamId = pathTeamName.slice(-1);

    axios
      .get(
        `site/get_players_site?id_team=${teamId}&filter=${this.state.selectedOption}`
      )
      .then((res) => {
        this.setState({
          players: res.data.players,
          staffMembers: res.data.staff,
          otherTeams: res.data.other_teams,
          team_name: res.data.team_name,
          loaded: true,
        });
      })
      .catch((err) => console.log(err));
  };

  slidePrev = () => this.setState({ activeIndex: this.state.activeIndex - 1 });
  slideNext = () => this.setState({ activeIndex: this.state.activeIndex + 1 });
  onSlideChanged = (item) => {
    this.setState({ activeIndex: item });
  };

  handleChangeMenu = (newValue) => {
    this.setState({ selectedOption: newValue }, this.getPlayers);
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    if (!this.state.has_access) {
      return <Redirect to="/groups" />;
    }

    const responsive = {
      // 0: { items: 1 },
      // 568: { items: 2 },
      // 1024: { items: 4 },
      0: { items: 1 },
      740: { items: 2 },
      1440: { items: 3 },
      1850: { items: 4 },
    };
    return (
      <div className="team-page">
        <div
          className="team-page-title notranslate"
          style={{ color: this.props.config_options.section_title_color }}
        >
          {this.state.team_name}
        </div>
        <div
          className="team-page-subtitle"
          style={{ color: this.props.config_options.section_title_color }}
        >
          Jucători
        </div>
        <div className="team-page-menu-desktop">
          {menuOptions.map((item) => {
            return (
              <div
                className={css`
                  padding-left: 50px;
                  padding-right: 50px;
                  display: flex;
                  align-items: center;
                  border-bottom: solid 1px
                    ${this.props.config_options.header_option_color};
                  color: ${this.state.selectedOption === item.value
                    ? this.props.config_options.header_color
                    : this.props.config_options.header_option_color};
                  background-color: ${this.state.selectedOption === item.value
                    ? this.props.config_options.header_option_color
                    : ""};
                  border-radius: ${this.state.selectedOption === item.value &&
                  this.props.config_options.id_theme == 2
                    ? "12px 12px 0px 0px"
                    : "0px"};
                  cursor: pointer;
                  fill: ${this.state.selectedOption === item.value
                    ? "white"
                    : this.props.config_options.main_color};

                  &:hover {
                    color: ${this.props.config_options.header_color};
                    fill: ${this.props.config_options.header_color};
                    background-color: ${this.props.config_options
                      .header_option_color};
                    border-radius: ${this.props.config_options.id_theme == 2
                      ? "12px 12px 0px 0px"
                      : "0px"};
                  }
                `}
                onClick={() => this.handleChangeMenu(item.value)}
              >
                {item.value === "goalkeeper" && (
                  <svg
                    className="team-page-option-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    version="1.1"
                    style={{
                      shapeRendering: "geometricPrecision",
                      textRendering: "geometricPrecision",
                      imageRendering: "optimizeQuality",
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                    }}
                    viewBox="0 0 6995 6995"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Layer_x0020_1">
                      <metadata id="CorelCorpID_0Corel-Layer" />
                      <polygon points="5246,0 6995,0 6995,1749 6995,6995 5246,6995 5246,1749 1749,1749 1749,6995 0,6995 0,1749 0,0 1749,0 " />
                    </g>
                  </svg>
                )}
                {item.value === "defender" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    version="1.1"
                    style={{
                      shapeRendering: "geometricPrecision",
                      textRendering: "geometricPrecision",
                      imageRendering: "optimizeQuality",
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                    }}
                    viewBox="0 0 6668 6666"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    className="team-page-option-icon"
                  >
                    <g id="Layer_x0020_1">
                      <metadata id="CorelCorpID_0Corel-Layer" />
                      <path d="M0 0l6668 0c0,3333 0,5000 -3334,6666 -3334,-1666 -3334,-3333 -3334,-6666z" />
                    </g>
                  </svg>
                )}
                {item.value === "midfielder" && (
                  <svg
                    className="team-page-option-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    version="1.1"
                    style={{
                      shapeRendering: "geometricPrecision",
                      textRendering: "geometricPrecision",
                      imageRendering: "optimizeQuality",
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                    }}
                    viewBox="0 0 6774 6560"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Layer_x0020_1">
                      <metadata id="CorelCorpID_0Corel-Layer" />
                      <path d="M4234 0c1460,376 2540,1701 2540,3280 0,1579 -1080,2904 -2540,3280l0 -6560zm-1694 6560c-1461,-376 -2540,-1701 -2540,-3280 0,-1579 1079,-2904 2540,-3280l0 6560z" />
                    </g>
                  </svg>
                )}
                {item.value === "forward" && (
                  <svg
                    className="team-page-option-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    version="1.1"
                    style={{
                      shapeRendering: "geometricPrecision",
                      textRendering: "geometricPrecision",
                      imageRendering: "optimizeQuality",
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                    }}
                    viewBox="0 0 3874 5165"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Layer_x0020_1">
                      <metadata id="CorelCorpID_0Corel-Layer" />
                      <polygon points="0,2582 1937,0 3874,2583 2582,2583 2582,5165 1291,5165 1291,2582 " />
                    </g>
                  </svg>
                )}
                {item.text}
              </div>
            );
          })}
        </div>
        {!this.state.loaded ? (
          <div className="loader-container">
            <Loader
              type="ThreeDots"
              color={this.props.config_options.main_color}
              height={80}
              width={80}
            />
          </div>
        ) : (
          <div className="players-list">
            {this.state.players?.Goalkeeper && (
              <div className="players-section">
                {this.state.players.Goalkeeper.map((item) => {
                  return (
                    <Player
                      player={item}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </div>
            )}
            {this.state.players?.Defender && (
              <div className="players-section">
                {this.state.players.Defender.map((item) => {
                  return (
                    <Player
                      player={item}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </div>
            )}
            {this.state.players?.Midfielder && (
              <div className="players-section">
                {this.state.players.Midfielder.map((item) => {
                  return (
                    <Player
                      player={item}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </div>
            )}
            {this.state.players?.Forward && (
              <div className="players-section">
                {this.state.players.Forward.map((item) => {
                  return (
                    <Player
                      player={item}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </div>
            )}
            {this.state.players?.Other && (
              <div className="players-section">
                {this.state.players.Other.map((item) => {
                  return (
                    <Player
                      player={item}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div
          className="team-page-subtitle"
          style={{ color: this.props.config_options.section_title_color }}
        >
          Staff tehnic
        </div>
        <div className="players-list">
          <div className="players-section">
            {this.state.staffMembers.map((item, index) => {
              return (
                <StaffMember
                  item={item}
                  key={index}
                  config_options={this.props.config_options}
                />
              );
            })}
          </div>
        </div>
        <div
          className="team-page-subtitle"
          style={{ color: this.props.config_options.section_title_color }}
        >
          Alte grupe
        </div>
        {this.state.width > 1024 && this.state.otherTeams.length < 4 ? (
          <div className="other-groups-carousel-container-not-enough-el">
            {this.state.otherTeams.map((item) => {
              return (
                <GroupElement
                  team={item}
                  config_options={this.props.config_options}
                />
              );
            })}
          </div>
        ) : (
          <div className="other-groups-carousel-container">
            <AliceCarousel
              mouseTracking
              disableDotsControls
              disableButtonsControls
              responsive={responsive}
              ref={(el) => (this.Carousel = el)}
            >
              {this.state.otherTeams.map((item) => {
                return (
                  <GroupElement
                    team={item}
                    config_options={this.props.config_options}
                  />
                );
              })}
            </AliceCarousel>
            <div className="other-groups-carousel-buttons">
              <div
                className="prev-button-desktop-teams"
                onClick={() => this.Carousel.slidePrev()}
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2
                      ? "0px 40px 40px 0px"
                      : "",
                  backgroundColor: this.props.config_options.side_buttons_color,
                }}
              >
                <LeftArrow
                  fill={this.props.config_options.play_icon_color}
                  className="prev-button-img"
                />
              </div>
              <div
                onClick={() => this.Carousel.slideNext()}
                className="next-button-desktop-teams"
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2
                      ? "40px 0px 0px 40px"
                      : "",
                  backgroundColor: this.props.config_options.side_buttons_color,
                }}
              >
                <RightArrow
                  fill={this.props.config_options.play_icon_color}
                  className="next-button-img"
                />
              </div>
            </div>
            <div className="other-groups-carousel-buttons-mobile">
              <div
                className="prev-button-desktop-teams"
                onClick={() => this.Carousel.slidePrev()}
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                  backgroundColor: this.props.config_options.buttons_color,
                }}
              >
                <LeftArrow
                  // fill={this.props.config_options.play_icon_color}
                  fill={this.props.config_options.buttons_text_color}
                  className="prev-button-img"
                />
              </div>
              <div
                onClick={() => this.Carousel.slideNext()}
                className="next-button-desktop-teams"
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                  backgroundColor: this.props.config_options.buttons_color,
                }}
              >
                <RightArrow
                  // fill={this.props.config_options.play_icon_color}
                  fill={this.props.config_options.buttons_text_color}
                  className="next-button-img"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Team;
