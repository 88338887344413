import React from "react";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";

export default function NewsElement(props) {
  const history = useHistory();
  return (
    <div
      className="news-preview-container"
      style={{ borderRadius: props.config_options.id_theme == 2 ? 15 : 0 }}
    >
      <div className="image-container">
        <img
          src={props.article.image}
          alt="article_image"
          style={{
            borderRadius:
              props.config_options.id_theme == 2 ? "15px 15px 0px 0px" : 0,
          }}
        />
        <div
          className="article-tag"
          style={{
            borderRadius:
              props.config_options.id_theme == 2 ? "0px 15px 0px 0px" : 0,
            color: props.config_options.news_description_color,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            version="1.1"
            style={{
              shapeRendering: "geometricPrecision",
              textRendering: "geometricPrecision",
              imageRendering: "optimizeQuality",
              fillRule: "evenodd",
              clipRule: "evenodd",
            }}
            viewBox="0 0 13966 13966"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="article-tag-img"
          >
            <defs>
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html: `.str0 {stroke:${props.config_options.clock_lines_icon_color};stroke-width:1552.37} .fil1 {fill:none} .fil0 {fill:${props.config_options.main_color}}`,
                }}
              />
            </defs>
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <circle className="fil0" cx={6983} cy={6983} r={6983} />
              <polyline
                className="fil1 str0"
                points="6983,4072 6983,6983 11057,6983 "
              />
            </g>
          </svg>
          {props.article.date}
        </div>
      </div>
      <div className="content-container">
        <div
          className="news-title"
          style={{ color: props.config_options.news_title_color }}
        >
          {props.article.title}
        </div>
        <div
          className="news-description"
          style={{ color: props.config_options.news_description_color }}
        >
          {props.article.preview}
        </div>
      </div>
      <div className="discover-more-button">
        <Button
          className="arrow-button"
          onClick={() =>
            history.push(
              `/news/${props.article.title
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9 \-]/g, "")
                .replaceAll(" ", "-")}-${props.article.id}`
            )
          }
          theme={props.config_options.id_theme}
          color={props.config_options.buttons_text_color}
          backgroundColor={props.config_options.buttons_color}
        >
          Află mai multe{" "}
          <ArrowRightIcon
            fill={props.config_options.buttons_text_color}
            className="discover-more-button-icon"
          />
        </Button>
      </div>
    </div>
  );
}
