import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowIcon } from './assets/icons/svg/arrow-down.svg';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    toTop: {
        zIndex: 2,
        width: 50,
        height: 50,
        position: 'fixed',
        bottom: '2vh',
        backgroundColor: '#DCDCDC',
        color: 'black',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: '#397BA6',
            backgroundColor: '#DCDCDC'
        },
        [theme.breakpoints.up('xs')]: {
            right: '5%',
            backgroundColor: (props) => props.config_options.main_color,
            // backgroundColor: "#01B1E4"
        },
        [theme.breakpoints.up('lg')]: {
            right: '6.5%',
        },
    },
    icon: {
        width: 15,
        height: 15,
        transform: 'rotate(180deg)',
        fill: (props) => props.config_options.buttons_text_color
        // fill: 'white'
    }
})
)

const Scroll = ({ showBelow, ...props }) => {
    const classes = useStyles(props);
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <div>
            {show &&
                <IconButton onClick={handleClick} className={classes.toTop} aria-label="to top" component="span">
                    <ArrowIcon className={classes.icon} />
                </IconButton>
            }
        </div>
    )
}
export default Scroll