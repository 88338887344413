import React, { useState, useEffect } from "react";
import "./Photos.scss";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as ExpandIcon } from "../../../../../assets/icons/svg/expand.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Expanded from "./Expanded";

export default function Photos(props) {
  const history = useHistory();
  const [images, setImages] = useState([]);
  const [expanded, setExpanded] = useState("");

  useEffect(() => {
    axios
      .get(`site/get_images_homepage?id_club=${props.id_club}`)
      .then((res) => setImages(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {images.length > 3 && (
        <div className="photos-section">
          <div className="photos-section-title" style={{ color: props.config_options.section_title_color }}>{props.title}</div>
          <div className="photos-grid-container">
            <div
              className="grid-column-left"
              onClick={() => setExpanded(`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[0].image_name}`)}
            >
              <img src={`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[0].image_name}`} alt="" style={{ borderRadius: props.theme == 2 ? 12 : 0 }} />
              <div className="expand-photo-button" style={{ borderRadius: props.theme == 2 ? '50%' : '', backgroundColor: props.config_options.expand_photo_button_color }}>
                <ExpandIcon className="expand-photo-button-img" fill={props.config_options.expand_photo_button_text_color} />
              </div>
            </div>
            <div
              className="grid-column-right"

            >
              <div className="grid-row-1" onClick={() => setExpanded(`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[1].image_name}`)}>
                <img src={`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[1].image_name}`} alt="" style={{ borderRadius: props.theme == 2 ? 12 : 0 }} />

                <div className="expand-photo-button" style={{ borderRadius: props.theme == 2 ? '50%' : '', backgroundColor: props.config_options.expand_photo_button_color }}>
                  <ExpandIcon className="expand-photo-button-img" fill={props.config_options.expand_photo_button_text_color} />
                </div>
              </div>
              <div
                className="grid-row-2"

              >
                <div className="grid-column-right-1" onClick={() => setExpanded(`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[2].image_name}`)}>
                  <img src={`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[2].image_name}`} alt="" style={{ borderRadius: props.theme == 2 ? 12 : 0 }} />

                  <div className="expand-photo-button" style={{ borderRadius: props.theme == 2 ? '50%' : '', backgroundColor: props.config_options.expand_photo_button_color }}>
                    <ExpandIcon className="expand-photo-button-img" fill={props.config_options.expand_photo_button_text_color} />
                  </div>
                </div>
                <div
                  className="grid-column-right-2"
                  onClick={() => setExpanded(`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[3].image_name}`)}
                >
                  <img src={`https://api.thefootballbrain.app/uploads/site/photos/${props.id_club}/${images[3].image_name}`} alt="" style={{ borderRadius: props.theme == 2 ? 12 : 0 }} />

                  <div className="expand-photo-button" style={{ borderRadius: props.theme == 2 ? '50%' : '', backgroundColor: props.config_options.expand_photo_button_color }}>
                    <ExpandIcon className="expand-photo-button-img" fill={props.config_options.expand_photo_button_text_color} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="photos-button-row">
            <Button onClick={() => history.push("/photos")} theme={props.config_options.id_theme} backgroundColor={props.config_options.buttons_color} color={props.config_options.buttons_text_color}>
              Vezi toata galeria{" "}
              <ArrowRight fill={props.config_options.buttons_text_color} className="see-all-photos-icon" />
            </Button>
          </div>
          {expanded !== "" && (
            <Expanded closeExpand={() => setExpanded("")} image={expanded} config_options={props.config_options} />
          )}
        </div>
      )}
    </>
  );
}
