import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/svg/close.svg";
import "./VideoElementExpanded.scss";
import { ReactComponent as PlayIcon } from "../../../../../assets/icons/svg/play.svg";

function useOutsideAlerter(ref, props) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.closeExpand();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const toHHMMSS = (secs) => {
  const sec = parseInt(secs, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - (hours * 3600)) / 60);
  let seconds = sec - (hours * 3600) - (minutes * 60);

  if (hours < 10 && hours > 0) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours != 0 ? (hours + ':' + minutes + ':' + seconds) : (minutes + ':' + seconds);
}

export default function VideoElementExpanded(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props);

  const [playingTag, setPlayingTag] = useState(props.video_data.video_tags[0]);
  const [currentTime, setCurrentTime] = useState('');

  // document.getElementsByClassName('popup-container')[0].getElementsByClassName('play-icon-container')[0].addEventListener("mouseover", function (event) {
  //   // highlight the mouseover target
  //   document.getElementsByClassName('video').style.filter = 'brightness(80%)';
  // }, false);

  return (
    <div className="dark-background-popup-homepage">
      <div className="popup-container">
        <div className="data-video-container">
          <div className="video-title">
            {props.video_data.data_video.video_name}
          </div>
          <div
            className="video-events"
            style={{
              height: document.getElementsByClassName('dark-background-popup-homepage').length > 0 ? `calc(${document.getElementsByClassName('dark-background-popup-homepage')[0].clientHeight}px - ${document.getElementsByClassName('video-container')[0].clientHeight}px - 250px)` : "380px"
            }}>
            {props.video_data.video_tags.map((elem, index) => {
              return (
                <div
                  className="video-event"
                  onClick={() => {
                    setPlayingTag(elem);
                  }}
                >
                  <div className="video-event-container">
                    <div className="video-event-play-info">
                      <div
                        className="video-event-playing"
                        style={{
                          display: elem.data_video_tag.id_video_tag == playingTag.data_video_tag.id_video_tag ? "block" : "none"
                        }}>
                      </div>
                      <div className="video-event-info">
                        <div className="video-event-minute">
                          {toHHMMSS(elem.data_video_tag.second_start)}
                        </div>
                        <div className="video-event-name">
                          {elem.data_video_tag.video_tag_title}
                        </div>
                      </div>
                    </div>
                    <div className="video-event-play">
                      <PlayIcon fill={props.config_options.play_icon_color} className="play-icon" />

                    </div>
                  </div>
                  <div className="video-event-line">
                  </div>
                  <div className="video-event-timeline"
                    style={{
                      width: elem.data_video_tag.id_video_tag == playingTag.data_video_tag.id_video_tag && Math.trunc(currentTime) >= Math.trunc(playingTag.data_video_tag.second_start) && Math.trunc(currentTime) <= Math.trunc(playingTag.data_video_tag.second_final) ?
                        `${((currentTime - elem.data_video_tag.second_start) / (elem.data_video_tag.second_final - elem.data_video_tag.second_start) * (document.getElementsByClassName('video-event-line').length > 0 ? document.getElementsByClassName('video-event-line')[0].clientWidth : 0))}px` :
                        "0px",
                      maxWidth: document.getElementsByClassName('video-event-line').length > 0 ? `${document.getElementsByClassName('video-event-line')[0].clientWidth - 0}px` : "380px"
                    }}
                  >
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div 
        className="video-container"
        style={{
          height: document.getElementsByClassName('video-player').length > 0 ? `${document.getElementsByClassName('video-player')[0].clientHeight - 0}px` : "380px"
        }}
        >
          <video
            className="video-player"
            src={`${props.video_data.data_video.video_url}#t=${playingTag.data_video_tag.second_start},${playingTag.data_video_tag.second_final}`}
            // src={`${props.video_data.data_video.video_url}`}
            // controls
            type="video/mp4"
            style={{
              width: "100%",
              margin: "auto",
              filter: currentTime < playingTag.data_video_tag.second_start || currentTime > playingTag.data_video_tag.second_final ?
                "brightness(60%)" : "brightness(100%)"
            }}
            autoPlay
            onTimeUpdate={(video) => {
              setCurrentTime(video.currentTarget.currentTime);
            }}
          />
          {currentTime < playingTag.data_video_tag.second_start || currentTime > playingTag.data_video_tag.second_final && true &&
            <div
              className="play-icon-container"
              style={{
                backgroundColor: props.config_options.buttons_color,
                top: `-${(document.getElementsByClassName('video-player')[0].clientHeight / 2) + 40}px`,
                right: `-${(document.getElementsByClassName('video-player')[0].clientWidth / 2) - 40}px`,
              }}
            >
              <PlayIcon fill={props.config_options.buttons_text_color} className="play-icon" />
            </div>
          }
        </div>
        <div className="close-button" onClick={() => props.closeExpand()} style={{
          borderRadius: props.config_options.id_theme == 2 ? '50% ' : 0, backgroundColor: props.config_options.buttons_color
        }}>
          <CloseIcon fill={props.config_options.buttons_text_color} className="close-button-img" />
        </div>
      </div>
    </div>
  );
}
