import React from "react";
import "./Groups.scss";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";

export default function GroupIndividual(props) {
  const history = useHistory();

  function addDefaultSrc(event) {
    event.target.src = `https://api.thefootballbrain.app/uploads/staff_members/placeholder_antrenor.png`;
  }

  return (
    <div
      className="group-element-individual "
      style={{ borderRadius: props.config_options.id_theme == 2 ? 15 : 0 }}
    >
      <div
        className="group-name notranslate"
        style={{ color: props.config_options.groups_name_color }}
      >
        {props.team ? props.team.team_name : ""}
      </div>
      <div
        className="group-coach"
        style={{ color: props.config_options.groups_coach_color }}
      >
        <img
          src={`https://api.thefootballbrain.app/uploads/staff_members/${props.team.id_coach}.jpg`}
          alt="coach"
          className="avatar"
          onError={addDefaultSrc}
        />
        <div>
          <div className="coach">Antrenor:</div>
          <div className="coach-name notranslate">
            {props.team ? props.team.coach_name : ""}
          </div>
        </div>
      </div>
      <div className="discover-more-button">
        <Button
          className="arrow-button"
          onClick={() =>
            history.push(
              `/groups/${props.team.team_name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9 \-]/g, "")
                .replaceAll(" ", "-")}-${props.team.id_team}`
            )
          }
          theme={props.config_options.id_theme}
          color={props.config_options.buttons_text_color}
          backgroundColor={props.config_options.buttons_color}
        >
          Află mai multe{" "}
          <ArrowRightIcon
            fill={props.config_options.buttons_text_color}
            className="discover-more-button-icon"
          />
        </Button>
      </div>
    </div>
  );
}
