import React, { useState, useEffect } from "react";
import {
    useStripe,
    useElements,
    PaymentElement,
} from "@stripe/react-stripe-js";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/svg/arrow-left.svg";
import Button from "../../reusable/Button/Button";
import "./PersonalDataForm.scss"
import Loader from "react-loader-spinner";
import "../Competitions/Competitions.scss";
import axios from "axios";

const SetupForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [finalLoader, setFinalLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);


    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setFinalLoader(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setFinalLoader(false);
            return;
        }
        const { error, result } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url:
                    `https://${props.urlRedirect}/online-payments-success`,
            },
        });


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (e.g., payment
            // details incomplete)
            setFinalLoader(false);
            setErrorMessage(error.message);
        } else {
            // stripe
            //     .confirmCardPayment(props.clientSecret, {
            //         payment_method: {
            //             card: cardElement,
            //             billing_details: {
            //                 name: 'Jenny Rosen',
            //             },
            //         },
            //     })
            //     .then(function (result) {
            //         // Handle result.error or result.paymentIntent
            //     });
            setFinalLoader(true);
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.

        }
    };

    return (
        <div>
            <div className="setup-form-container" style={{ display: props.toShow ? "block" : "none", color: props.config_options.contact_color }}>
                <div className="setup-form-container-title" style={{ display: !finalLoader ? "block" : "none" }}>
                    Pas 2 din 2
                </div>
                <form onSubmit={handleSubmit} style={{ textAlign: "-webkit-center", display: !finalLoader ? "block" : "none" }}>
                    <div className="form-part" >
                        <PaymentElement />
                        {errorMessage && <div style={{ marginTop: "10px", textAlign: "left" }}>{errorMessage}</div>}
                        <div className="form-buttons" style={{ display: "inline-flex", gap: "30px", marginTop: "30px" }}>
                            <Button style={{ marginBottom: "30px" }}
                                onClick={props.onClickBack}
                                theme={props.config_options.id_theme}
                                color={props.config_options.buttons_text_color}
                                backgroundColor={props.config_options.buttons_color}>
                                <LeftArrowIcon fill={props.config_options.buttons_text_color} className="send-icon left" />
                                ÎNAPOI
                            </Button>
                            <Button disabled={!stripe}
                                // style={{
                                // borderRadius: "0px",
                                // backgroundColor: `rgb(251, 235, 36)`,
                                // color: `rgb(0, 0, 0)`,
                                // }}
                                theme={props.config_options.id_theme}
                                color={props.config_options.buttons_text_color}
                                backgroundColor={props.config_options.buttons_color}>PLĂTEȘTE
                                <ArrowRight fill={props.config_options.buttons_text_color} className="send-icon rigth" /></Button>
                            {/* Show error message to your customers */}
                        </div>
                    </div>
                </form >
            </div>
            <div className="loader-container" style={{ display: finalLoader ? "block" : "none" }}>
                <Loader type="ThreeDots" color={props.config_options.main_color} height={80} width={80} />
            </div>
        </div>
    );
};

export default SetupForm;
