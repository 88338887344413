import React, { Component } from "react";
import "./App.css";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop"; //Scroll to top on page change
import Scroll from "./ScrollTop"; //Scroll to top button
import { Suspense } from "react";
import BgImg from "./assets/custom/bkg.png";

export class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(props);
    this.sponsorsRef = React.createRef();
    this.contactRef = React.createRef();
  }
  scrollToSponsors = () =>
    this.sponsorsRef.scrollIntoView({ behavior: "smooth", block: "start" });

  scrollToContact = () =>
    this.contactRef.scrollIntoView({ behavior: "smooth", block: "start" });

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    const {
      id_club,
      club_name,
      homepage_description,
      main_details_received,
      theme,
      main_color,
      config_options,
      show_online_payments,
      show_only_online_payments,
      fee_passed_to_customer,
      show_donations,
      multi_lang,
      lang,
      url_redirect_payment,
      setLang,
    } = this.props;

    const sections = {
      header: require(`./themes/${theme}/components/sections/Header/Header`)
        .default,
      footer: require(`./themes/${theme}/components/sections/Footer/Footer`)
        .default,
      sponsors:
        require(`./themes/${theme}/components/sections/Sponsors/Sponsors`)
          .default,
      contact: require(`./themes/${theme}/components/sections/Contact/Contact`)
        .default,
    };

    const pages = {
      homepage: require(`./themes/${theme}/components/pages/Homepage`).default,
      news: require(`./themes/${theme}/components/pages/News/News`).default,
      newsIndividual:
        require(`./themes/${theme}/components/pages/NewsIndividual/NewsIndividual`)
          .default,
      team: require(`./themes/${theme}/components/pages/Team/Team`).default,
      gallery: require(`./themes/${theme}/components/pages/Gallery/Gallery`)
        .default,
      competitions:
        require(`./themes/${theme}/components/pages/Competitions/Competitions`)
          .default,
      competitionPage:
        require(`./themes/${theme}/components/pages/Competitions/CompetitionPage`)
          .default,
      about: require(`./themes/${theme}/components/pages/About/About`).default,
      onlinePayments:
        require(`./themes/${theme}/components/pages/OnlinePayments/OnlinePayments`)
          .default,
      donations:
        require(`./themes/${theme}/components/pages/Donations/Donations`)
          .default,
      onlinePaymentsSuccess:
        require(`./themes/${theme}/components/pages/OnlinePayments/OnlinePaymentsSuccess`)
          .default,
      donationSuccess:
        require(`./themes/${theme}/components/pages/Donations/DonationSuccess`)
          .default,
      groups: require(`./themes/${theme}/components/pages/Groups/Groups`)
        .default,
      staffMemberPage:
        require(`./themes/${theme}/components/pages/StaffMemberPage/StaffMemberPage`)
          .default,
      playerPage:
        require(`./themes/${theme}/components/pages/PlayerPage/PlayerPage`)
          .default,
      staffMembers:
        require(`./themes/${theme}/components/pages/StaffMembers/StaffMembers`)
          .default,
    };
    const Header = sections.header;
    const Footer = sections.footer;
    const Sponsors = sections.sponsors;
    const Contact = sections.contact;

    const Homepage = pages.homepage;
    const News = pages.news;
    const NewsIndividual = pages.newsIndividual;
    const Team = pages.team;
    const Competitions = pages.competitions;
    const CompetitionPage = pages.competitionPage;
    const About = pages.about;
    const OnlinePayments = pages.onlinePayments;
    const Donations = pages.donations;
    const OnlinePaymentsSuccess = pages.onlinePaymentsSuccess;
    const DonationSuccess = pages.donationSuccess;
    const Groups = pages.groups;
    const StaffMemberPage = pages.staffMemberPage;
    const PlayerPage = pages.playerPage;
    const StaffMembers = pages.staffMembers;
    const Gallery = pages.gallery;

    return (
      <Router>
        <ScrollToTop>
          {main_details_received && (
            <div
              className="app"
              style={{
                backgroundColor: config_options.background_color,
                background:
                  theme == 3
                    ? `url(${BgImg}) no-repeat center center fixed`
                    : "",
                backgroundSize: theme == 3 ? "cover" : "",
              }}
            >
              <Header
                scrollToSponsors={this.scrollToSponsors}
                scrollToContact={this.scrollToContact}
                id_club={id_club}
                club_name={club_name}
                color={config_options.header_color}
                textColor={config_options.header_option_color}
                theme={theme}
                config_options={config_options}
                onSetLang={setLang}
                showOnlinePayments={show_online_payments}
                showDonations={show_donations}
                showOnlyOnlinePayments={show_only_online_payments}
                multiLang={multi_lang}
              />
              <Switch>
                <Route
                  exact
                  path="/about"
                  forceRefresh={true}
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <About
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/online-payments"
                  render={(props) =>
                    show_online_payments == 0 ? (
                      <Redirect to="/" />
                    ) : (
                      <OnlinePayments
                        {...props}
                        id_club={id_club}
                        club_name={club_name}
                        config_options={config_options}
                        lang={lang}
                        urlRedirectPayment={url_redirect_payment}
                        feePassedToCustomer={fee_passed_to_customer}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/donations"
                  render={(props) =>
                    // this.state.show_donations == 0 ? (
                    false ? (
                      <Redirect to="/" />
                    ) : (
                      <Donations
                        {...props}
                        id_club={id_club}
                        club_name={club_name}
                        config_options={config_options}
                        lang={lang}
                        feePassedToCustomer={fee_passed_to_customer}
                        urlRedirectPayment={url_redirect_payment}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/online-payments-success"
                  render={(props) =>
                    show_online_payments == 0 ? (
                      <Redirect to="/" />
                    ) : (
                      <OnlinePaymentsSuccess
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                        feePassedToCustomer={fee_passed_to_customer}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/donation-success"
                  render={(props) =>
                    // this.state.show_donations == 0 ? (
                    false ? (
                      <Redirect to="/" />
                    ) : (
                      <DonationSuccess
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                        feePassedToCustomer={fee_passed_to_customer}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/news"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <News
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/news/:title-:id"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <NewsIndividual
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/groups"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <Groups
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/groups/:team_name-:id"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <Team
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path={`/player/:name-:id`}
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <PlayerPage
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/photos"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <Gallery
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/competitions"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <Competitions
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/competitions/:competition_name-:id"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <CompetitionPage
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/staff"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <StaffMembers
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />

                <Route
                  exact
                  path="/staff/:staff_member_name-:id"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <StaffMemberPage
                        {...props}
                        id_club={id_club}
                        config_options={config_options}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/"
                  render={(props) =>
                    show_only_online_payments == 1 ? (
                      <Redirect to="/online-payments" />
                    ) : (
                      <Homepage
                        {...props}
                        id_club={id_club}
                        club_name={club_name}
                        description={homepage_description}
                        theme={theme}
                        main_color={main_color}
                        config_options={config_options}
                      />
                    )
                  }
                />
              </Switch>
              {show_only_online_payments == 0 && (
                <div ref={(ref) => (this.sponsorsRef = ref)}>
                  <Sponsors id_club={id_club} config_options={config_options} />
                </div>
              )}
              <div ref={(ref) => (this.contactRef = ref)}>
                <Contact
                  id_club={id_club}
                  club_name={club_name}
                  config_options={config_options}
                />
              </div>
              <Footer
                scrollToSponsors={this.scrollToSponsors}
                scrollToContact={this.scrollToContact}
                id_club={id_club}
                club_name={club_name}
                backgroundColor={main_color}
                theme={theme}
                config_options={config_options}
                showOnlinePayments={show_online_payments}
                showDonations={show_donations}
                showOnlyOnlinePayments={show_only_online_payments}
              />
              <Scroll showBelow={250} config_options={config_options} />
            </div>
          )}
        </ScrollToTop>
      </Router>
    );
  }
}

export default Navigation;
