import React, { useState } from "react";
import "./Social.scss";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/svg/youtube.svg";
import { ReactComponent as FacebookIcon } from "../../../../../assets/icons/svg/facebook.svg";
import { ReactComponent as PlayIcon } from "../../../../../assets/icons/svg/play.svg";
import { ReactComponent as LinkIcon } from "../../../../../assets/icons/svg/link.svg";
import Moment from "react-moment";
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const getHref = (type, id, full_url) => {
  if (type === "Youtube") {
    return `https://youtube.com/watch?v=${id}`
  }
  else if (type === "Facebook") {
    let new_id = id.split("_")[1];
    return full_url;
  }
  else return "";
}

export default function SocialElement(props) {
  const [openedVideo, setOpenedVideo] = useState("");

  return (
    <div className="social-element-container" style={{ borderRadius: props.theme == 2 ? 15 : 0, color: props.config_options.social_text_color }}>
      <div
        className="image-container"
        onClick={props.item.post_network === "Youtube" ? () => setOpenedVideo(props.item.post_network_id) : () => { }}
      >
        {props.item.post_network === "Facebook" ?
          (<a
            href={getHref(props.item.post_network, props.item.post_network_id, props.item.post_url)}
            target="_blank"
          >
            <img
              src={props.item.post_thumbnail_url}
              alt="article_image"
              style={{ cursor: "pointer", borderRadius: props.theme == 2 ? '15px 15px 0px 0px' : '' }}
            />

          </a>) :
          (<img
            src={props.item.post_thumbnail_url}
            alt="article_image"
            style={{ cursor: "pointer", borderRadius: props.theme == 2 ? '15px 15px 0px 0px' : '' }}
          />)
        }

        <div className="element-tag" style={{ borderRadius: props.theme == 2 ? '0px 15px 0px 0px' : 0 }}>
          <div className="image-container">
            {props.item.post_network === "Youtube" && <YoutubeIcon className="tag-image" fill={props.config_options.header_option_color} />}
            {props.item.post_network === "Facebook" && <FacebookIcon className="tag-image" fill={props.config_options.header_option_color} />}
          </div>
          <div className="tag-name">
            <Moment format="DD.MM.YYYY, HH:mm">
              {props.item.post_publish_date}
            </Moment>
          </div>
        </div>
        {props.item.post_network === "Youtube" && <PlayIcon fill={props.config_options.play_icon_color} className="play-icon" />}
      </div>

      <div className="content-container notranslate">
        {props.item.post_title}
        <a
          href={getHref(props.item.post_network, props.item.post_network_id, props.item.post_url)}
          target="_blank"
        >
          <LinkIcon className="link-icon" fill={props.config_options.header_option_color} />
        </a>
      </div>
      <Dialog
        open={openedVideo !== ""}
        onClose={() => setOpenedVideo("")}
        maxWidth="xl"
      >
        <DialogContent className="embed-video-dialog-content">
          <div className="embed-video-container">
            <ReactPlayer
              url={`https://youtube.com/watch?v=${openedVideo}`}
              controls={true}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
