import React from "react";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";
import "./News.scss";

export default function NewsElement(props) {
  const history = useHistory();
  return (
    <div className="news-element-container-3">
      <div className="image-container">
        <img src={props.news.image} alt="article_image" />
      </div>
      <div className="content-container">
        <div
          className="news-title"
          style={{ color: props.config_options.news_title_color }}
        >
          {props.news.title}
        </div>
        <div
          className="news-description"
          style={{ color: props.config_options.news_description_color }}
        >
          {props.news.preview}
        </div>
      </div>
      <div className="discover-more-button">
        <Button
          className="arrow-button-3"
          theme={props.config_options.id_theme}
          backgroundColor={props.config_options.buttons_color}
          color={props.config_options.buttons_text_color}
          onClick={() =>
            history.replace(
              `/news/${props.news.title
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z0-9 \-]/g, "")
                .replaceAll(" ", "-")}-${props.news.id}`
            )
          }
        >
          Află mai multe{" "}
          <ArrowRightIcon
            fill={props.config_options.buttons_text_color}
            className="discover-more-button-icon-3"
          />
        </Button>
      </div>
    </div>
  );
}
