import React from "react";
import "./News.scss";
import NewsElement from "./NewsElement";
import { ReactComponent as LeftArrow } from "../../../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../../../../assets/icons/svg/arrow-right.svg";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
    };
  }

  componentDidMount = () => {
    this.getNews();
  };

  getNews = () => {
    // check if url includes /news/

    if (/news/.test(window.location.href)) {
      const pathNewsName = this.props.except_id.split("-");
      const newsExceptId = pathNewsName.slice(-1)[0];
      axios
        .get(
          `site/get_site_news?id_club=${this.props.id_club}&exception=${newsExceptId}`
        )
        .then((res) => this.setState({ news: res.data.news }))
        .catch((err) => console.log(err));
    } else {
      // const pathNewsName = this.props.except_id.split("-");
      // const newsExceptId = pathNewsName.slice(-1)[0];
      axios
        .get(
          `site/get_site_news?id_club=${this.props.id_club}&exception=${this.props.except_id}`
        )
        .then((res) => this.setState({ news: res.data.news }))
        .catch((err) => console.log(err));
    }
  };

  render() {
    const responsive = {
      // 0: { items: 1 },
      // 568: { items: 2 },
      // 1024: { items: 4 },
      0: { items: 1 },
      740: { items: 2 },
      1440: { items: 2 },
      1850: { items: 3 },
    };

    return (
      <>
        {this.state.news.length > 0 && (
          <div className="news-section-3">
            <div
              className="news-section-title"
              style={{ color: this.props.config_options.section_title_color }}
            >
              {this.props.title}
            </div>
            <div className="news-carousel-container-3">
              <AliceCarousel
                mouseTracking
                disableDotsControls
                disableButtonsControls
                responsive={responsive}
                ref={(el) => (this.Carousel = el)}
              >
                {this.state.news.map((item) => {
                  return (
                    <NewsElement
                      news={item}
                      config_options={this.props.config_options}
                    />
                  );
                })}
              </AliceCarousel>
              <div className="news-carousel-buttons-mobile-3">
                <div
                  className="prev-button-mobile-news"
                  onClick={() => this.Carousel.slidePrev()}
                  style={{
                    backgroundColor:
                      this.props.config_options.side_buttons_color,
                    borderRadius: "50%",
                  }}
                >
                  <LeftArrow
                    fill={this.props.config_options.play_icon_color}
                    className="prev-button-img"
                  />
                </div>
                <div
                  onClick={() => this.Carousel.slideNext()}
                  className="next-button-mobile-news"
                  style={{
                    backgroundColor:
                      this.props.config_options.side_buttons_color,
                    borderRadius: "50%",
                  }}
                >
                  <RightArrow
                    fill={this.props.config_options.play_icon_color}
                    className="next-button-img"
                  />
                </div>
              </div>
              <div className="news-carousel-buttons-desktop-3">
                <div
                  className="prev-button-desktop-news"
                  onClick={() => this.Carousel.slidePrev()}
                  style={{
                    borderRadius: "0px 40px 40px 0px",
                    backgroundColor:
                      this.props.config_options.side_buttons_color,
                  }}
                >
                  <LeftArrow
                    className="prev-button-img"
                    fill={this.props.config_options.play_icon_color}
                  />
                </div>
                <div
                  onClick={() => this.Carousel.slideNext()}
                  className="next-button-desktop-news"
                  style={{
                    borderRadius: "40px 0px 0px 40px",
                    backgroundColor:
                      this.props.config_options.side_buttons_color,
                  }}
                >
                  <RightArrow
                    className="next-button-img"
                    fill={this.props.config_options.play_icon_color}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default News;
