import React from "react";
import "./PlayerPage.scss";
import axios from "axios";
import PlayerElementButton from "./PlayerElementButton";
import Loader from "react-loader-spinner";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";
import { ControlsStrategy } from "react-alice-carousel/lib/types";
import { ReactComponent as RightArrow } from "../../../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as LeftArrow } from "../../../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as PlayIcon } from "../../../../../assets/icons/svg/play.svg";
import VideoElementExpanded from "./VideoElementExpanded";
import { Redirect } from "react-router-dom";

class PlayerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerData: {},
      width: 0,
      loaded: false,
      mainIndex: -1,
      nextIndex: -1,
      prevIndex: -1,
      isOpen: false,
      linePhoto: 0,
      linePhotoMobile: 0,
      lineVideo: 0,
      linesPhoto: 0,
      linesPhotoMobile: 0,
      linesVideo: 0,
      showVideoExpanded: false,
      video_data: "",
      has_access: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount = () => {
    this.hasAccessToPlayer();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  hasAccessToPlayer = () => {
    const pathPlayerName = this.props.match.params.id.split("-");
    const playerId = pathPlayerName.slice(-1)[0];
    axios
      .post("site/has_access_to_player", {
        id_club: this.props.id_club,
        id_player: playerId,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data == 0) {
          this.setState({
            has_access: false,
          });
        } else {
          this.getPlayerData();
        }
      });
  };

  getPlayerData = () => {
    const pathPlayerName = this.props.match.params.id.split("-");
    const playerId = pathPlayerName.slice(-1);
    axios
      .get(`site/get_player_site?id_player=${playerId}`)
      .then((res) => {
        const linesPhoto = Math.ceil(res.data[0].images.length / 8);
        const linesPhotoMobile = Math.ceil(res.data[0].images.length / 6);
        const linesVideo = Math.ceil(res.data[0].video_gallery.length / 4);
        this.setState({
          playerData: res.data[0],
          loaded: true,
          linesPhoto: linesPhoto,
          linePhoto: linesPhoto > 0 ? 1 : 0,
          linesPhotoMobile: linesPhotoMobile,
          linePhotoMobile: linesPhotoMobile > 0 ? 1 : 0,
          linesVideo: linesVideo,
          lineVideo: linesVideo > 0 ? 1 : 0,
        });
      })
      .catch((err) => console.log(err));
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  addDefaultSrc = (event) => {
    event.target.src = `https://api.thefootballbrain.app/uploads/players/placeholder.png`;
  };

  onOpenVideo = () => {
    this.setState();
  };

  render() {
    const segmentStandard = (
      <div
        className="segment"
        style={{ color: this.props.config_options.article_paragraph_color }}
      ></div>
    );
    const segmentValue = (
      <div
        className="segment"
        style={{ borderTopColor: this.props.config_options.buttons_color }}
      ></div>
    );
    const goalkeeper = (
      <div
        className="icon-position"
        style={{ fill: this.props.config_options.buttons_color }}
      >
        <svg
          className="team-page-option-icon"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          version="1.1"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
          }}
          viewBox="0 0 6995 6995"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <polygon points="5246,0 6995,0 6995,1749 6995,6995 5246,6995 5246,1749 1749,1749 1749,6995 0,6995 0,1749 0,0 1749,0 " />
          </g>
        </svg>
      </div>
    );

    const defender = (
      <div
        className="icon-position"
        style={{ fill: this.props.config_options.buttons_color }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          version="1.1"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
          }}
          viewBox="0 0 6668 6666"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="team-page-option-icon"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <path d="M0 0l6668 0c0,3333 0,5000 -3334,6666 -3334,-1666 -3334,-3333 -3334,-6666z" />
          </g>
        </svg>
      </div>
    );

    const midfielder = (
      <div
        className="icon-position"
        style={{ fill: this.props.config_options.buttons_color }}
      >
        <svg
          className="team-page-option-icon"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          version="1.1"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
          }}
          viewBox="0 0 6774 6560"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <path d="M4234 0c1460,376 2540,1701 2540,3280 0,1579 -1080,2904 -2540,3280l0 -6560zm-1694 6560c-1461,-376 -2540,-1701 -2540,-3280 0,-1579 1079,-2904 2540,-3280l0 6560z" />
          </g>
        </svg>
      </div>
    );

    const forward = (
      <div
        className="icon-position"
        style={{ fill: this.props.config_options.buttons_color }}
      >
        <svg
          className="team-page-option-icon"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          version="1.1"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
          }}
          viewBox="0 0 3874 5165"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <polygon points="0,2582 1937,0 3874,2583 2582,2583 2582,5165 1291,5165 1291,2582 " />
          </g>
        </svg>
      </div>
    );

    if (!this.state.has_access) {
      return <Redirect to="/groups" />;
    }

    const { mainIndex, nextIndex, prevIndex, isOpen } = this.state;

    return !this.state.loaded ? (
      <div className="loader-container">
        <Loader
          type="ThreeDots"
          color={this.props.config_options.main_color}
          height={80}
          width={80}
        />
      </div>
    ) : (
      <>
        <div className="player-page-container">
          <div className="player-image-album">
            <div className="player-image">
              <img
                src={`https://api.thefootballbrain.app/uploads/players/${this.state.playerData.id}.jpg`}
                alt="placeholder"
                onError={this.addDefaultSrc}
                style={{
                  borderRadius:
                    this.props.config_options.id_theme === 2 ? 15 : 0,
                }}
              />
            </div>
            <div className="player-photo-gallery-container">
              <div className="photo-gallery-header">
                <div className="photo-gallery-title">Galerie foto</div>
                {this.state.linesPhoto > 1 && (
                  <div className="photo-gallery-buttons-nav">
                    <LeftArrow
                      fill={this.props.config_options.side_buttons_color}
                      className="prev-button-img"
                      onClick={() => {
                        if (this.state.linePhoto > 1) {
                          this.setState({
                            linePhoto: this.state.linePhoto - 1,
                          });
                        }
                      }}
                    />
                    <RightArrow
                      fill={this.props.config_options.side_buttons_color}
                      className="next-button-img"
                      onClick={() => {
                        if (this.state.linePhoto < this.state.linesPhoto) {
                          this.setState({
                            linePhoto: this.state.linePhoto + 1,
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              {isOpen && (
                <Lightbox
                  mainSrc={this.state.playerData.images[mainIndex].file_name}
                  nextSrc={this.state.playerData.images[nextIndex].file_name}
                  prevSrc={this.state.playerData.images[prevIndex].file_name}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      mainIndex: prevIndex,
                      prevIndex:
                        prevIndex == 0
                          ? this.state.playerData.images.length - 1
                          : prevIndex - 1,
                      nextIndex:
                        nextIndex == 0
                          ? this.state.playerData.images.length - 1
                          : nextIndex - 1,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      mainIndex: nextIndex,
                      prevIndex:
                        prevIndex == this.state.playerData.images.length - 1
                          ? 0
                          : prevIndex + 1,
                      nextIndex:
                        nextIndex == this.state.playerData.images.length - 1
                          ? 0
                          : nextIndex + 1,
                    })
                  }
                />
              )}
              <div className="player-photo-gallery">
                {this.state.playerData.images.map((elem, index) => {
                  return (
                    <img
                      src={elem.file_name}
                      alt="placeholder"
                      onError={this.addDefaultSrc}
                      style={{
                        // height: "120px",
                        // width: "auto",
                        cursor: "pointer",
                        borderRadius:
                          this.props.config_options.id_theme === 2 ? 15 : 0,
                        display:
                          Math.ceil((index + 1) / 8) == this.state.linePhoto
                            ? "block"
                            : "none",
                      }}
                      onClick={() => {
                        this.setState({
                          isOpen: true,
                          mainIndex: index,
                          nextIndex:
                            index == this.state.playerData.images.length - 1
                              ? 0
                              : index + 1,
                          prevIndex:
                            index == 0
                              ? this.state.playerData.images.length - 1
                              : index - 1,
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="player-video-gallery-container">
              <div className="video-gallery-header">
                <div className="video-gallery-title">Galerie video</div>
                {this.state.linesVideo > 1 && (
                  <div className="video-gallery-buttons-nav">
                    <LeftArrow
                      fill={this.props.config_options.side_buttons_color}
                      className="prev-button-img"
                      onClick={() => {
                        if (this.state.lineVideo > 1) {
                          this.setState({
                            lineVideo: this.state.lineVideo - 1,
                          });
                        }
                      }}
                    />
                    <RightArrow
                      fill={this.props.config_options.side_buttons_color}
                      className="next-button-img"
                      onClick={() => {
                        if (this.state.lineVideo < this.state.linesVideo) {
                          this.setState({
                            lineVideo: this.state.lineVideo + 1,
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="player-video-gallery">
                {this.state.playerData.video_gallery.map((elem, index) => {
                  return (
                    <div
                      className="video-preview-container"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          showVideoExpanded: true,
                          video_data: elem,
                        });
                      }}
                    >
                      <video
                        className="video-preview"
                        src={elem.data_video.video_url}
                        controls={false}
                        type="video/mp4"
                        style={{
                          display:
                            Math.ceil((index + 1) / 4) == this.state.lineVideo
                              ? "block"
                              : "none",
                        }}
                      />
                      <div className="play-icon-container">
                        <PlayIcon
                          fill={this.props.config_options.play_icon_color}
                          className="play-icon"
                        />
                      </div>
                      <div className="video-name">
                        {elem.data_video.video_name}
                      </div>
                    </div>
                  );
                })}
                {/* {this.state.playerData.video_gallery.map((elem, index) => {
                                        return (
                                            <div className="video-preview-container">
                                                <video
                                                    className="video-preview"
                                                    src={elem.data_video.video_url}
                                                    controls={false}
                                                    type="video/mp4"
                                                />
                                                <div className="play-icon-container">
                                                    <PlayIcon fill={this.props.config_options.play_icon_color} className="play-icon" />
                                                </div>
                                                <div className="video-name">
                                                    {elem.data_video.video_name}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {this.state.playerData.video_gallery.map((elem, index) => {
                                        return (
                                            <div className="video-preview-container">
                                                <video
                                                    className="video-preview"
                                                    src={elem.data_video.video_url}
                                                    controls={false}
                                                    type="video/mp4"
                                                />
                                                <div className="play-icon-container">
                                                    <PlayIcon fill={this.props.config_options.play_icon_color} className="play-icon" />
                                                </div>
                                                <div className="video-name">
                                                    {elem.data_video.video_name}
                                                </div>
                                            </div>
                                        )
                                    })} */}
              </div>
            </div>
          </div>
          <div className="player-details">
            <div className="player-name-number">
              <div
                className="player-number"
                style={{ color: this.props.config_options.buttons_color }}
              >
                {this.state.playerData.shirt_number > 0
                  ? this.state.playerData.shirt_number
                  : "#"}
              </div>
              <div className="vertical-line"></div>
              <div
                className="player-name"
                style={{
                  color: this.props.config_options.article_subtitle_color,
                }}
              >
                {this.state.playerData.name}
              </div>
            </div>
            <hr />
            {this.state.playerData.dob != null ? (
              <div className="player-date-of-birth">
                <div className="field-name">Data nașterii</div>
                <div className="field-value">
                  {/* {this.state.playerData.dob} */}
                  {new Date(this.state.playerData.dob).toLocaleDateString(
                    "ro-RO",
                    { dateStyle: "long" }
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div className="player-info-role-leg">
              {this.state.playerData.position != null ? (
                <div className="position pos-primary">
                  <div className="field-name">Poziție principală</div>
                  <div className="field-value">
                    {
                      {
                        Goalkeeper: goalkeeper,
                        Forward: forward,
                        Midfielder: midfielder,
                        Defender: defender,
                      }[this.state.playerData.position]
                    }
                    {this.state.playerData.position}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.playerData.position_alternative != null ? (
                <>
                  <div className="vertical-line"></div>
                  <div className="position pos-secondary">
                    <div className="field-name">Poziție secundară</div>
                    <div className="field-value">
                      {
                        {
                          Goalkeeper: goalkeeper,
                          Forward: forward,
                          Midfielder: midfielder,
                          Defender: defender,
                        }[this.state.playerData.position_alternative]
                      }
                      {this.state.playerData.position_alternative}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.playerData.main_foot != null ? (
                <>
                  <div className="vertical-line second"></div>
                  <div className="position pos-strong-leg">
                    <div className="field-name">Picior de bază</div>
                    <div className="field-value">
                      {this.state.playerData.main_foot}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <hr />
            {this.state.playerData.bio != null ? (
              <div className="player-bibliography">
                <div className="title-field title-bibliography">Biografie</div>
                <div
                  className="text-bilbiography"
                  style={{
                    color: this.props.config_options.article_paragraph_color,
                    marginBottom: "10px",
                  }}
                >
                  {this.state.playerData.bio}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.playerData.params.filter((elem) => {
              if (elem.parameter_value != null) {
                return elem;
              }
            }).length != 0 ? (
              <div className="player-performance">
                <div className="title-field title-performance">
                  Puncte forte
                </div>
                {this.state.playerData.params.map((elem) => {
                  if (parseInt(elem.parameter_value) > 3) {
                    return (
                      <div className="performance">
                        <div className="title-field-performance">
                          {elem.report_parameter_name}
                        </div>
                        <div className="value-field-performance">
                          {elem.parameter_value}/5
                        </div>
                        <div className="segments segments-performance">
                          {[1, 2, 3, 4, 5].map((elem2) => {
                            if (elem2 < parseInt(elem.parameter_value) + 1) {
                              return segmentValue;
                            }
                            return segmentStandard;
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="player-photo-gallery-container-mobile">
          <div className="photo-gallery-header">
            <div className="photo-gallery-title">Galerie foto</div>
            {this.state.linesPhotoMobile > 1 && (
              <div className="photo-gallery-buttons-nav">
                <LeftArrow
                  fill={this.props.config_options.side_buttons_color}
                  className="prev-button-img"
                  onClick={() => {
                    if (this.state.linePhotoMobile > 1) {
                      this.setState({
                        linePhotoMobile: this.state.linePhotoMobile - 1,
                      });
                    }
                  }}
                />
                <RightArrow
                  fill={this.props.config_options.side_buttons_color}
                  className="next-button-img"
                  onClick={() => {
                    if (
                      this.state.linePhotoMobile < this.state.linesPhotoMobile
                    ) {
                      this.setState({
                        linePhotoMobile: this.state.linePhotoMobile + 1,
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={this.state.playerData.images[mainIndex].file_name}
              nextSrc={this.state.playerData.images[nextIndex].file_name}
              prevSrc={this.state.playerData.images[prevIndex].file_name}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  mainIndex: prevIndex,
                  prevIndex:
                    prevIndex == 0
                      ? this.state.playerData.images.length - 1
                      : prevIndex - 1,
                  nextIndex:
                    nextIndex == 0
                      ? this.state.playerData.images.length - 1
                      : nextIndex - 1,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  mainIndex: nextIndex,
                  prevIndex:
                    prevIndex == this.state.playerData.images.length - 1
                      ? 0
                      : prevIndex + 1,
                  nextIndex:
                    nextIndex == this.state.playerData.images.length - 1
                      ? 0
                      : nextIndex + 1,
                })
              }
            />
          )}
          <div className="player-photo-gallery">
            {this.state.playerData.images.map((elem, index) => {
              return (
                <img
                  src={elem.file_name}
                  alt="placeholder"
                  onError={this.addDefaultSrc}
                  style={{
                    // height: "120px",
                    // width: "auto",
                    cursor: "pointer",
                    borderRadius:
                      this.props.config_options.id_theme === 2 ? 15 : 0,
                    display:
                      Math.ceil((index + 1) / 6) == this.state.linePhotoMobile
                        ? "block"
                        : "none",
                  }}
                  onClick={() => {
                    this.setState({
                      isOpen: true,
                      mainIndex: index,
                      nextIndex:
                        index == this.state.playerData.images.length - 1
                          ? 0
                          : index + 1,
                      prevIndex:
                        index == 0
                          ? this.state.playerData.images.length - 1
                          : index - 1,
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="player-video-gallery-container-mobile">
          <div className="video-gallery-header">
            <div className="video-gallery-title">Galerie video</div>
            {this.state.linesVideo > 1 && (
              <div className="video-gallery-buttons-nav">
                <LeftArrow
                  fill={this.props.config_options.side_buttons_color}
                  className="prev-button-img"
                  onClick={() => {
                    if (this.state.lineVideo > 1) {
                      this.setState({
                        lineVideo: this.state.lineVideo - 1,
                      });
                    }
                  }}
                />
                <RightArrow
                  fill={this.props.config_options.side_buttons_color}
                  className="next-button-img"
                  onClick={() => {
                    if (this.state.lineVideo < this.state.linesVideo) {
                      this.setState({
                        lineVideo: this.state.lineVideo + 1,
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="player-video-gallery">
            {this.state.playerData.video_gallery.map((elem, index) => {
              return (
                <div
                  className="video-preview-container"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      showVideoExpanded: true,
                      video_data: elem,
                    });
                  }}
                >
                  <video
                    className="video-preview"
                    src={elem.data_video.video_url}
                    controls={false}
                    type="video/mp4"
                  />
                  <div className="play-icon-container">
                    <PlayIcon
                      fill={this.props.config_options.play_icon_color}
                      className="play-icon"
                    />
                  </div>
                  <div className="video-name">{elem.data_video.video_name}</div>
                </div>
              );
            })}
            {/* {this.state.playerData.video_gallery.map((elem, index) => {
                                return (
                                    <div
                                        className="video-preview-container"
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                showVideoExpanded: true,
                                                video_data: elem
                                            })
                                        }}
                                    >
                                        <video
                                            className="video-preview"
                                            src={elem.data_video.video_url}
                                            controls={false}
                                            type="video/mp4"
                                            style={{
                                                display: Math.ceil((index + 1) / 4) == this.state.lineVideo ? "block" : "none"
                                            }}
                                        />
                                        <div className="play-icon-container">
                                            <PlayIcon fill={this.props.config_options.play_icon_color} className="play-icon" />
                                        </div>
                                        <div className="video-name">
                                            {elem.data_video.video_name}
                                        </div>
                                    </div>
                                )
                            })} */}
            {/* {this.state.playerData.video_gallery.map((elem, index) => {
                                return (
                                    <div className="video-preview-container">
                                        <video
                                            className="video-preview"
                                            src={elem.data_video.video_url}
                                            controls={false}
                                            type="video/mp4"
                                        />
                                        <div className="play-icon-container">
                                            <PlayIcon fill={this.props.config_options.play_icon_color} className="play-icon" />
                                        </div>
                                        <div className="video-name">
                                            {elem.data_video.video_name}
                                        </div>
                                    </div>
                                )
                            })} */}
          </div>
        </div>
        {/* <div className="photo-video-gallery">
                        <div className="title-field title-gallery">
                            Galerie foto video
                        </div>
                        <div className="gallery-content">
                            <div className="player-image-album">
                                <div className="player-photo-gallery">
                                    {isOpen && <Lightbox
                                        mainSrc={this.state.playerData.images[mainIndex].file_name}
                                        nextSrc={this.state.playerData.images[nextIndex].file_name}
                                        prevSrc={this.state.playerData.images[prevIndex].file_name}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                mainIndex: prevIndex,
                                                prevIndex: prevIndex == 0 ? this.state.playerData.images.length - 1 : prevIndex - 1,
                                                nextIndex: nextIndex == 0 ? this.state.playerData.images.length - 1 : nextIndex - 1
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                mainIndex: nextIndex,
                                                prevIndex: prevIndex == this.state.playerData.images.length - 1 ? 0 : prevIndex + 1,
                                                nextIndex: nextIndex == this.state.playerData.images.length - 1 ? 0 : nextIndex + 1,
                                            })
                                        }
                                    />}
                                    {this.state.playerData.images.map((elem, index) => {
                                        return <img
                                            src={elem.file_name}
                                            alt="placeholder"
                                            onError={this.addDefaultSrc}
                                            style={{
                                                height: "120px",
                                                width: "auto",
                                                cursor: "pointer",
                                                borderRadius:
                                                    this.props.config_options.id_theme === 2 ? 15 : 0,
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    isOpen: true,
                                                    mainIndex: index,
                                                    nextIndex: index == this.state.playerData.images.length - 1 ? 0 : index + 1,
                                                    prevIndex: index == 0 ? this.state.playerData.images.length - 1 : index - 1
                                                });
                                            }}
                                        />
                                    })}
                                </div>
                                <div className="player-video-gallery"></div>
                            </div>
                        </div>
                    </div> */}
        <div className="buttons-previous-next">
          <PlayerElementButton
            arrowDirection="previous"
            player={
              this.state.playerData.prev.length == 1
                ? this.state.playerData.prev[0]
                : ""
            }
            config_options={this.props.config_options}
          />
          <PlayerElementButton
            arrowDirection="next"
            player={
              this.state.playerData.next.length == 1
                ? this.state.playerData.next[0]
                : ""
            }
            config_options={this.props.config_options}
          />
        </div>
        {this.state.showVideoExpanded && (
          <VideoElementExpanded
            closeExpand={() => this.setState({ showVideoExpanded: false })}
            config_options={this.props.config_options}
            video_data={this.state.video_data}
          />
        )}
      </>
    );
  }
}

export default PlayerPage;
