import React, { useState, useEffect } from "react";
import arrow_down from "../../../../../assets/icons/arrow-down.png";
import axios from "axios";

export default function CompetitionRanking(props) {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [teams, setTeams] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    axios
      .get(
        `match/competition_ranking?id_comp=${props.id_competition}&id_club=${props.id_club}`
      )
      .then((res) => setTeams(res.data))
      .catch((err) => console.log(err));
  }, []);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  function expandRow(value) {
    if (activeIndexes.includes(value)) {
      setActiveIndexes((oldArray) => oldArray.filter((x) => x != value));
    } else {
      setActiveIndexes((oldArray) => [...oldArray, value]);
    }
  }

  return (
    <div className="competition-ranking-container-3">
      <div className="ranking-table-titles">
        <div className="position">Poziția</div>
        <div className="team-name">Echipa</div>
        <div className="team-stat">Jocuri</div>
        <div className="team-stat">Victorii</div>
        <div className="team-stat">Egaluri</div>
        <div className="team-stat">Înfrângeri</div>
        <div className="team-stat">Golaveraj</div>
        <div className="team-stat">Puncte</div>
        <div className="team-shape">Formă</div>
        <div className="team-details">Detalii</div>
      </div>
      <div className="teams-list">
        {teams.map((item, index) => {
          return (
            <>
              <div
                className="team-row"
                key={index}
                onClick={width < 1024 ? () => expandRow(index) : () => {}}
                style={{
                  marginBottom: activeIndexes.includes(index) ? 0 : "",
                  color: props.config_options.background_color,
                  borderRadius: activeIndexes.includes(index)
                    ? "15px 15px 0px 0px"
                    : 15,
                }}
              >
                <div className="position">{index + 1}.</div>
                <div className="team-name">
                  {/* <img src={logo} alt="logo" /> */}
                  <div className="name">
                    {item.team_name.toUpperCase()}
                    {item.own_team === "1" && (
                      <div
                        className="own-team-bar"
                        style={{
                          backgroundColor: props.config_options.main_color,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="team-stat">{item.matches_played}</div>
                <div className="team-stat">{item.matches_won}</div>
                <div className="team-stat">{item.matches_draw}</div>
                <div className="team-stat">{item.matches_lost}</div>
                <div className="team-stat">
                  {item.goals_scored}-{item.goals_conceeded}
                </div>
                <div className="team-stat">{item.points}</div>
                <div className="team-shape">
                  {item.form.map((result) => {
                    return (
                      <>
                        {result == "W" && <div className="green-dot" />}
                        {result == "L" && <div className="red-dot" />}
                        {result == "D" && <div className="yellow-dot" />}
                      </>
                    );
                  })}
                </div>
                <div className="team-details">
                  <img src={arrow_down} alt="arrow" />
                </div>
              </div>
              {activeIndexes.includes(index) && (
                <div
                  className="team-row-expanded-mobile"
                  style={{
                    marginBottom: 15,
                    borderRadius: "0px 0px 15px 15px",
                  }}
                >
                  <div className="stat-box">
                    <div className="stat-name">Jocuri</div>
                    <div className="stat-value">{item.matches_played}</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-name">Victorii</div>
                    <div className="stat-value">{item.matches_won}</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-name">Egaluri</div>
                    <div className="stat-value">{item.matches_draw}</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-name">Înfrângeri</div>
                    <div className="stat-value">{item.matches_lost}</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-name">Goalveraj</div>
                    <div className="stat-value">
                      {item.goals_scored}-{item.goals_conceeded}
                    </div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-name">Puncte</div>
                    <div className="stat-value">{item.points}</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-name">Formă</div>
                    <div className="stat-value">
                      {item.form.slice(0, 4).map((result, index) => {
                        return (
                          <>
                            {result == "W" && <div className="green-dot" />}
                            {result == "L" && <div className="red-dot" />}
                            {result == "D" && <div className="yellow-dot" />}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
