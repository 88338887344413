import React from "react";
import "./HomepageIntro.scss";
import { ReactComponent as ArrowDownIcon } from "../../../../../assets/icons/svg/arrow-down.svg";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import Button from "../../reusable/Button/Button";
import { useHistory } from "react-router-dom";

export default function HomepageIntro(props) {
  const history = useHistory();
  return (
    <div className="container-homepage">
      <img src={`https://api.thefootballbrain.app/uploads/site/homepage_hero_images/${props.id_club}.jpg`} alt="hero_image" style={{ borderRadius: props.theme == 2 ? 12 : 0 }} />
      <div className="intro-content-container">
        <div className="title notranslate">{props.club_name}</div>
        <div className="description">
          {props.description}
        </div>
        <div className="button-row">
          <Button
            onClick={() => history.push("/about")}
            theme={props.config_options.id_theme}
            backgroundColor={props.config_options.header_color}
            color={props.config_options.header_option_color}>
            AFLĂ MAI MULTE{" "}
            <ArrowRightIcon
              fill={props.config_options.header_option_color}
              className="discover-more-button-icon"
            />
          </Button>
        </div>
      </div>
      <div
        className="more-button"
        onClick={() => props.scrollToNews()}
        style={{ cursor: "pointer", backgroundColor: props.main_color }}
      >
        <ArrowDownIcon fill={props.config_options.buttons_text_color} className="more-button-img" />
      </div>
    </div>
  );
}
