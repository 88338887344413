import React, { useState, useEffect } from "react";
import "./Contact.scss";
import { ReactComponent as HomeIcon } from "../../../../../assets/icons/svg/home.svg";
import { ReactComponent as PhoneIcon } from "../../../../../assets/icons/svg/phone.svg";
import { ReactComponent as EmailIcon } from "../../../../../assets/icons/svg/email.svg";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import close from "../../../../../assets/icons/close.png";
import Input from "../../reusable/Input/Input";
import Button from "../../reusable/Button/Button";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

export default function Contact(props) {
  const [last_name, setName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [error, setError] = useState("");

  const [contactDetails, setContactDetails] = useState({});

  useEffect(() => {
    axios.get(`site/get_contact_details?id_club=${props.id_club}`)
      .then((res) => setContactDetails(res.data))
      .catch(err => console.log(err))
  }, []);


  function submitMessage() {
    const payload = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      email: email,
      message: message,
      id_club: props.id_club
    };

    if (
      last_name !== "" &&
      first_name !== "" &&
      phone !== "" &&
      email !== "" &&
      message !== ""
    ) {
      axios
        .post("site/contact_form", payload)
        .then((res) => {
          setName("");
          setFirstName("");
          setPhone("");
          setEmail("");
          setMessage("");
          setConfirmPopup(true);
          setError("");
        })
        .catch((err) => console.log(err));
    } else {
      setError("Toate campurile sunt obligatorii");
    }
  }

  const [styleFocused1, setStyleFocused1] = useState({ 'border-bottom': `1px solid grey` });
  const [styleFocused2, setStyleFocused2] = useState({ 'border-bottom': `1px solid grey` });
  const [styleFocused3, setStyleFocused3] = useState({ 'border-bottom': `1px solid grey` });
  const [styleFocused4, setStyleFocused4] = useState({ 'border-bottom': `1px solid grey` });
  const [styleFocused5, setStyleFocused5] = useState({ 'border-bottom': `1px solid grey` });

  return (
    <div className="contact-container" style={{ color: props.config_options.contact_color }}>
      <div className="contact-title">Contact</div>
      <div className="contact-content">
        <div className="contact-form-container">
          <div className="section-title" style={{ marginBottom: 80 }}>
            Formular de contact
          </div>
          <div className="form-content">
            <div className="form-row">
              <Input
                name="name"
                label="Nume"
                value={last_name}
                handleChange={(e) => setName(e.target.value)}
                style={styleFocused1}
                onFocus={() => {
                  setStyleFocused1({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                }}
                onBlur={() => {
                  setStyleFocused1({ 'border-bottom': `1px solid grey` });
                }}
                halfWidth
              />
              <Input
                name="first_name"
                label="Prenume"
                value={first_name}
                handleChange={(e) => setFirstName(e.target.value)}
                style={styleFocused2}
                onFocus={() => {
                  setStyleFocused2({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                }}
                onBlur={() => {
                  setStyleFocused2({ 'border-bottom': `1px solid grey` });
                }}
                halfWidth
              />
            </div>
            <div className="form-row">
              {" "}
              <Input
                name="phone"
                label="Telefon"
                value={phone}
                handleChange={(e) => setPhone(e.target.value)}
                style={styleFocused3}
                onFocus={() => {
                  setStyleFocused3({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                }}
                onBlur={() => {
                  setStyleFocused3({ 'border-bottom': `1px solid grey` });
                }}
                halfWidth
              />
              <Input
                name="email"
                label="Email"
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
                style={styleFocused4}
                onFocus={() => {
                  setStyleFocused4({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                }}
                onBlur={() => {
                  setStyleFocused4({ 'border-bottom': `1px solid grey` });
                }}
                halfWidth
              />
            </div>
            <div className="form-row">
              {" "}
              <Input
                name="message"
                label="Mesaj"
                value={message}
                handleChange={(e) => setMessage(e.target.value)}
                style={styleFocused5}
                onFocus={() => {
                  setStyleFocused5({ 'border-bottom': `1px solid ${props.config_options.buttons_color}` });
                }}
                onBlur={() => {
                  setStyleFocused5({ 'border-bottom': `1px solid grey` });
                }}
              />
            </div>
            {error !== "" && <div className="error-row">{error}</div>}
            <div className="button-row">
              <Button
                // style={{ width: window.innerWidth > 1024 ? 225 : 133 }}
                onClick={submitMessage}
                theme={props.config_options.id_theme}
                color={props.config_options.buttons_text_color}
                backgroundColor={props.config_options.buttons_color}
              >
                TRIMITE <ArrowRight fill={props.config_options.buttons_text_color} className="send-icon" />
              </Button>
            </div>
          </div>
        </div>
        <div className="contact-details-container">
          <div className="section-title">Date de contact</div>
          <div className="contact-details-content notranslate">
            <div className="address">
              <HomeIcon fill={props.config_options.contact_color} className="contact-details-icon" />
              {contactDetails.contact_address}
            </div>
            <div className="phone">
              <PhoneIcon fill={props.config_options.contact_color} className="contact-details-icon" />
              {contactDetails.contact_phone}
            </div>
            <div className="mail">
              <EmailIcon fill={props.config_options.contact_color} className="contact-details-icon" />
              {contactDetails.contact_email}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={confirmPopup} onClose={() => setConfirmPopup(false)}>
        <DialogContent>
          <div className="confirm-popup-container">
            <div className="confirm-popup-header">
              <div className="confirm-popup-title">Formular de contact</div>
              <img
                src={close}
                alt="close"
                style={{ width: 20, height: 20, cursor: "pointer" }}
                onClick={() => setConfirmPopup(false)}
              />
            </div>
            <div className="confirm-popup-row" style={{ marginBottom: 15 }}>
              Mesajul a fost trimis cu succes!
            </div>
            <div className="confirm-popup-row">
              Vei primi un raspuns din partea echipei {props.club_name} in cel mai
              scurt timp.
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
