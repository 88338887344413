import React, { useState } from "react";
import ContactInfo from "./ContactInfo";
import "./Header.scss";
import mobile_menu from "../../../../../assets/icons/menu-mobile.png";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/svg/close.svg";
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/svg/arrow-down.svg";
import { NavLink, useLocation } from "react-router-dom";
import { css } from "@emotion/css";
import Select, { components, GroupProps } from "react-select";

export default function Header(props) {
  const menuOptions = [
    {
      text: "Despre noi",
      subtitles: false,
      key: "about_us",
      path: "/about",
      scrollable: false,
    },
    {
      text: "Competiții",
      subtitles: false,
      key: "competitions",
      path: "/competitions",
      scrollable: false,
    },
    {
      text: "Grupe",
      subtitles: false,
      key: "groups",
      path: "/groups",
      scrollable: false,
    },
    {
      text: "Staff",
      subtitles: false,
      key: "staff",
      path: "/staff",
      scrollable: false,
    },
    {
      text: "Parteneri",
      subtitles: false,
      key: "partners",
      path: "/",
      scrollable: true,
    },
    {
      text: "Știri",
      subtitles: false,
      key: "news",
      path: "/news",
      scrollable: false,
    },
    // {
    //     text: "Plata online",
    //     subtitles: false,
    //     key: "online-payments",
    //     path: "/online-payments",
    //     scrollable: false,
    // },
    {
      text: "Contact",
      subtitles: false,
      key: "contact",
      path: "/",
      scrollable: true,
    },
  ];

  const languages = [
    {
      value: "ro",
      label: "RO",
    },
    {
      value: "en",
      label: "EN",
    },
  ];

  const location = useLocation();
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const [lang, setLang] = useState(languages[0]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: `${props.config_options.contact_color}`,
      color: "white",
      borderColor: `${props.config_options.contact_color}`,
      boxShadow: "none",
      borderWidth: "2px",
      borderRadius: "40px",
      "&:hover": {
        borderColor: `${props.config_options.contact_color}`,
      },
      cursor: "pointer",
      //height: "22px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "white",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
      fontSize: "14px",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      width: "2px",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: `${props.config_options.contact_color}`,
      borderColor: `${props.config_options.contact_color}`,
      borderWidth: "2px",
      borderRadius: "24px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "white"
        : `${props.config_options.contact_color}`,
      color: state.isSelected
        ? `${props.config_options.contact_color}`
        : "white",
      margin: "0px",
      fontSize: "14px",
      borderRadius:"24px",
      "&:hover": {
        cursor: "pointer",
      },
    }),
  };

  return (
    <div className="header-container-3">
      <div
        className="page-lang"
        style={{
          display: "flex",
          alignItems: "center",
          height: "50px",
          justifyContent: "flex-end",
        }}
      >
        <ContactInfo
          id_club={props.id_club}
          textColor={props.textColor}
          multiLang={props.multiLang}
        />
      </div>
      <div
        className="menu-header"
        style={{
          backgroundColor: props.color,
          borderRadius: 10,
        }}
      >
        <NavLink
          to={props.showOnlyOnlinePayments == 1 ? "/online-payments" : "/"}
          reloadDocument
          style={{ textDecoration: "none", color: "black" }}
          className="badge-name"
        >
          <img
            src={`https://api.thefootballbrain.app/uploads/club_logos/${props.id_club}.png`}
            alt="logo"
            className="mobile-logo-3"
          />
          <div
            className="title-text notranslate"
            style={{ color: props.textColor }}
          >
            {props.club_name}
          </div>
        </NavLink>
        {props.showOnlyOnlinePayments == 0 && (
          <div className="menu-options">
            {menuOptions.map((item, index) => {
              return (
                <>
                  {item.scrollable ? (
                    <div
                      className={css`
                        align-self: center;
                        padding-left: 50px;
                        padding-right: 50px;
                        font-size: 16px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        color: ${props.textColor};
                        cursor: pointer;
                        &:hover {
                          //   color: ${props.color};
                          background-color: #01b1e4;
                          border-radius: 40px;
                        }
                      `}
                      onClick={
                        item.key === "partners"
                          ? () => props.scrollToSponsors()
                          : () => props.scrollToContact()
                      }
                      style={{
                        borderLeft:
                          props.theme == 2
                            ? "none"
                            : `solid 1px ${props.config_options.header_lines_color}`,
                      }}
                    >
                      <div className="menu-option-text">
                        {item.text}
                        {item.subtitles && (
                          <ArrowDown className="arrow-down-menu" />
                        )}
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={item.path}
                      reloadDocument
                      style={{
                        textDecoration: "none",
                        color: props.textColor,
                      }}
                      key={index}
                    >
                      <div
                        className={css`
                          align-self: center;
                          padding-left: 50px;
                          padding-right: 50px;
                          font-size: 16px;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          color: ${
                            item.path === location.pathname && item.path !== "/"
                              ? props.color
                              : props.textColor
                          };
                          background-color: ${
                            item.path === location.pathname && item.path !== "/"
                              ? props.textColor
                              : props.color
                          };
                          border-radius: ${
                            item.path === location.pathname && item.path !== "/"
                              ? "40px"
                              : "0px"
                          };
                          cursor: pointer;
                          &:hover {
                            // color: ${props.color};
                            background-color: #01b1e4;
                            border-radius: 40px};
                          }
                        `}
                        style={{
                          borderLeft:
                            props.theme == 2
                              ? "none"
                              : `solid 1px ${props.config_options.header_lines_color}`,
                        }}
                      >
                        <div className="menu-option-text">
                          {item.text}
                          {item.subtitles && (
                            <ArrowDown className="arrow-down-menu" />
                          )}
                        </div>
                      </div>
                    </NavLink>
                  )}
                </>
              );
            })}
            {props.showOnlinePayments == 1 &&
              [
                {
                  text: "Plata online",
                  subtitles: false,
                  key: "online-payments",
                  path: "/online-payments",
                  scrollable: false,
                },
              ].map((item, index) => {
                return (
                  <>
                    {item.scrollable ? (
                      <div
                        className={css`
                          align-self: center;
                          padding-left: 50px;
                          padding-right: 50px;
                          font-size: 16px;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          color: ${props.textColor};
                          cursor: pointer;
                          &:hover {
                            // color: ${props.color};
                            background-color: #01b1e4;
                            border-radius: 40px;
                          }
                        `}
                        onClick={
                          item.key === "partners"
                            ? () => props.scrollToSponsors()
                            : () => props.scrollToContact()
                        }
                        style={{
                          borderLeft:
                            props.theme == 2
                              ? "none"
                              : `solid 1px ${props.config_options.header_lines_color}`,
                        }}
                      >
                        <div className="menu-option-text">
                          {item.text}
                          {item.subtitles && (
                            <ArrowDown className="arrow-down-menu" />
                          )}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={item.path}
                        reloadDocument
                        style={{
                          textDecoration: "none",
                          color: props.textColor,
                        }}
                        key={index}
                      >
                        <div
                          className={css`
                            align-self: center;
                            padding-left: 50px;
                            padding-right: 50px;
                            font-size: 16px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            color: ${item.path === location.pathname &&
                            item.path !== "/"
                              ? props.color
                              : props.textColor};
                            background-color: ${item.path ===
                              location.pathname && item.path !== "/"
                              ? props.textColor
                              : props.color};
                            border-radius: ${item.path === location.pathname &&
                            item.path !== "/"
                              ? "40px"
                              : "0px"};
                            cursor: pointer;
                            &:hover {
                              //   color: ${props.color};
                              background-color: #01b1e4;
                              border-radius: 40px;
                            }
                          `}
                          style={{
                            borderLeft:
                              props.theme == 2
                                ? "none"
                                : `solid 1px ${props.config_options.header_lines_color}`,
                          }}
                        >
                          <div className="menu-option-text">
                            {item.text}
                            {item.subtitles && (
                              <ArrowDown className="arrow-down-menu" />
                            )}
                          </div>
                        </div>
                      </NavLink>
                    )}
                  </>
                );
              })}
            {props.showDonations == 1 &&
              [
                {
                  text: "Donații",
                  subtitles: false,
                  key: "donations",
                  path: "/donations",
                  scrollable: false,
                },
              ].map((item, index) => {
                return (
                  <>
                    {item.scrollable ? (
                      <div
                        className={css`
                          align-self: center;
                          padding-left: 50px;
                          padding-right: 50px;
                          font-size: 16px;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          color: ${props.textColor};
                          cursor: pointer;
                          &:hover {
                            // color: ${props.color};
                            background-color: #01b1e4;
                            border-radius: 40px;
                          }
                        `}
                        onClick={
                          item.key === "partners"
                            ? () => props.scrollToSponsors()
                            : () => props.scrollToContact()
                        }
                        style={{
                          borderLeft:
                            props.theme == 2
                              ? "none"
                              : `solid 1px ${props.config_options.header_lines_color}`,
                        }}
                      >
                        <div className="menu-option-text">
                          {item.text}
                          {item.subtitles && (
                            <ArrowDown className="arrow-down-menu" />
                          )}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={item.path}
                        reloadDocument
                        style={{
                          textDecoration: "none",
                          color: props.textColor,
                        }}
                        key={index}
                      >
                        <div
                          className={css`
                            align-self: center;
                            padding-left: 50px;
                            padding-right: 50px;
                            font-size: 16px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            color: ${item.path === location.pathname &&
                            item.path !== "/"
                              ? props.color
                              : props.textColor};
                            background-color: ${item.path ===
                              location.pathname && item.path !== "/"
                              ? props.textColor
                              : props.color};
                            border-radius: ${item.path === location.pathname &&
                            item.path !== "/"
                              ? "40px"
                              : "0px"};
                            cursor: pointer;
                            &:hover {
                              //   color: ${props.color};
                              background-color: #01b1e4;
                              border-radius: 40px;
                            }
                          `}
                          style={{
                            borderLeft: `solid 1px ${props.config_options.header_lines_color}`,
                          }}
                        >
                          <div className="menu-option-text">
                            {item.text}
                            {item.subtitles && (
                              <ArrowDown className="arrow-down-menu" />
                            )}
                          </div>
                        </div>
                      </NavLink>
                    )}
                  </>
                );
              })}
          </div>
        )}
        {props.showOnlyOnlinePayments == 0 && (
          <div className="menu-mobile-3">
            {mobileMenuActive === false ? (
              <img
                src={mobile_menu}
                className="menu-mobile-img"
                alt=""
                onClick={() => setMobileMenuActive(true)}
                style={{ backgroundColor: props.textColor }}
              />
            ) : (
              <CloseIcon
                fill={props.textColor}
                className="menu-mobile-img"
                onClick={() => setMobileMenuActive(false)}
              />
            )}
          </div>
        )}
      </div>
      {mobileMenuActive && props.showOnlyOnlinePayments == 0 && (
        <div
          className="menu-mobile-content-3"
          style={{ backgroundColor: props.color }}
        >
          {menuOptions.map((item, index) => {
            return (
              <>
                {!item.scrollable ? (
                  <NavLink
                    to={item.path}
                    reloadDocument
                    style={{
                      textDecoration: "none",
                      color: props.textColor,
                    }}
                    key={index}
                    onClick={() => setMobileMenuActive(false)}
                  >
                    <div
                      className="mobile-menu-option"
                      style={{
                        borderBottom: `solid 1px ${props.config_options.header_lines_color}`,
                      }}
                    >
                      {item.text}
                    </div>
                  </NavLink>
                ) : (
                  <div
                    className="mobile-menu-option"
                    style={{
                      color: props.textColor,
                      borderBottom: `solid 1px ${props.config_options.header_lines_color}`,
                    }}
                    onClick={
                      item.key === "partners"
                        ? () => props.scrollToSponsors()
                        : () => props.scrollToContact()
                    }
                  >
                    {item.text}
                  </div>
                )}
              </>
            );
          })}
          {props.showDonations == 1 &&
            [
              {
                text: "Donații",
                subtitles: false,
                key: "donations",
                path: "/donations",
                scrollable: false,
              },
            ].map((item, index) => {
              return (
                <>
                  {!item.scrollable ? (
                    <NavLink
                      to={item.path}
                      reloadDocument
                      style={{
                        textDecoration: "none",
                        color: props.textColor,
                      }}
                      key={index}
                      onClick={() => {
                        setMobileMenuActive(false);
                      }}
                    >
                      <div
                        className="mobile-menu-option"
                        style={{
                          borderBottom: `solid 1px ${props.config_options.header_lines_color}`,
                        }}
                      >
                        {item.text}
                      </div>
                    </NavLink>
                  ) : (
                    <div
                      className="mobile-menu-option"
                      style={{
                        color: props.textColor,
                        borderBottom: `solid 1px ${props.config_options.header_lines_color}`,
                      }}
                      onClick={
                        item.key === "partners"
                          ? () => props.scrollToSponsors()
                          : () => props.scrollToContact()
                      }
                    >
                      {item.text}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      )}
    </div>
  );
}
