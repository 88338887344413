import React from "react";
import "./StaffMemberPage.scss";
import axios from "axios";
import AliceCarousel from "react-alice-carousel";
import StaffMemberSlider from "./StaffMemberSlider";
import { ReactComponent as LeftArrow } from "../../../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../../../../assets/icons/svg/arrow-right.svg";
import { Redirect } from "react-router-dom";

class StaffMemberPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffData: {},
      otherStaff: [],
      width: 0,
      has_access: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount = () => {
    this.hasAccessToStaff();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  hasAccessToStaff = () => {
    const pathStaffName = this.props.match.params.id.split("-");
    const staffId = pathStaffName.slice(-1)[0];
    axios
      .post("site/has_access_to_staff", {
        id_club: this.props.id_club,
        id_staff_member: staffId,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data == 0) {
          this.setState({
            has_access: false,
          });
        } else {
          this.getStaffData();
        }
      });
  };

  getStaffData = () => {
    const pathStaffName = this.props.match.params.id.split("-");
    const staffId = pathStaffName.slice(-1);

    axios
      .get(
        `site/get_staff_member_profile?id_staff=${staffId}&id_club=${this.props.id_club}`
      )
      .then((res) =>
        this.setState({
          staffData: res.data.staff_data,
          otherStaff: res.data.other_staff,
        })
      )
      .catch((err) => console.log(err));
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getStaffData();
    }
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  addDefaultSrc = (event) => {
    event.target.src = `https://api.thefootballbrain.app/uploads/staff_members/placeholder_antrenor.png`;
  };

  slidePrev = () => this.setState({ activeIndex: this.state.activeIndex - 1 });
  slideNext = () => this.setState({ activeIndex: this.state.activeIndex + 1 });

  render() {
    if (!this.state.has_access) {
      return <Redirect to="/staff" />;
    }

    const responsive = {
      // 0: { items: 1 },
      // 568: { items: 2 },
      // 1024: { items: 3 },
      // 1300: { items: 3 },
      0: { items: 1 },
      1024: { items: 2 },
      1440: { items: 3 },
      1850: { items: 4 },
    };
    return (
      <>
        <div className="staff-member-page-container">
          {Object.keys(this.state.staffData).length > 0 && (
            <div className="staff-image">
              <img
                src={`https://api.thefootballbrain.app/uploads/staff_members/${this.state.staffData.id_staff_member}.jpg`}
                alt="placeholder"
                onError={this.addDefaultSrc}
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? 15 : 0,
                }}
              />
            </div>
          )}
          <div className="staff-details">
            <div
              className="staff-name"
              style={{
                color: this.props.config_options.article_subtitle_color,
              }}
            >
              {this.state.staffData.staff_member_name}
            </div>
            <div
              className="staff-role"
              style={{
                color: this.props.config_options.article_paragraph_color,
              }}
            >
              {this.state.staffData.staff_member_role}
            </div>
            <div
              className="staff-description"
              style={{
                color: this.props.config_options.article_paragraph_color,
              }}
            >
              {this.state.staffData.staff_member_description}
            </div>
          </div>
        </div>
        <div
          className="staff-member-page-slider-title"
          style={{
            color: this.props.config_options.section_title_color,
          }}
        >
          Alți membri staff
        </div>
        {this.state.otherStaff.length < 4 && this.state.width > 1024 ? (
          <div className="other-staff-carousel-container-not-enough-el">
            {this.state.otherStaff.map((item, index) => {
              return (
                <StaffMemberSlider
                  item={item}
                  config_options={this.props.config_options}
                  key={index}
                />
              );
            })}
          </div>
        ) : (
          <div className="other-staff-carousel-container">
            <AliceCarousel
              mouseTracking
              disableDotsControls
              disableButtonsControls
              responsive={responsive}
              ref={(el) => (this.Carousel = el)}
            >
              {this.state.otherStaff.map((item, index) => {
                return (
                  <StaffMemberSlider
                    item={item}
                    config_options={this.props.config_options}
                    key={index}
                  />
                );
              })}
            </AliceCarousel>
            <div className="other-staff-carousel-buttons">
              <div
                className="prev-button-desktop-teams"
                onClick={() => this.Carousel.slidePrev()}
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2
                      ? "0px 40px 40px 0px"
                      : "",
                  backgroundColor: this.props.config_options.side_buttons_color,
                }}
              >
                <LeftArrow
                  className="prev-button-img"
                  fill={this.props.config_options.play_icon_color}
                />
              </div>
              <div
                onClick={() => this.Carousel.slideNext()}
                className="next-button-desktop-teams"
                style={{
                  borderRadius:
                    this.props.config_options.id_theme == 2
                      ? "40px 0px 0px 40px"
                      : "",
                  backgroundColor: this.props.config_options.side_buttons_color,
                }}
              >
                <RightArrow
                  className="next-button-img"
                  fill={this.props.config_options.play_icon_color}
                />
              </div>
            </div>
            <div className="other-staff-carousel-buttons-mobile">
              <div
                className="prev-button-desktop-teams"
                onClick={() => this.Carousel.slidePrev()}
                style={{
                  backgroundColor: this.props.config_options.side_buttons_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <LeftArrow
                  fill={this.props.config_options.play_icon_color}
                  className="prev-button-img"
                />
              </div>
              <div
                onClick={() => this.Carousel.slideNext()}
                className="next-button-desktop-teams"
                style={{
                  backgroundColor: this.props.config_options.side_buttons_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <RightArrow
                  fill={this.props.config_options.play_icon_color}
                  className="next-button-img"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default StaffMemberPage;
