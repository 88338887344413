import React from "react";
import "./Team.scss";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";
import Button from "../../reusable/Button/Button";

export default function Player(props) {
  const history = useHistory();
  function addDefaultSrc(event) {
    event.target.src = `https://api.thefootballbrain.app/uploads/players/placeholder.png`;
  }

  return (
    <div
      className="player-card"
      style={{ borderRadius: props.config_options.id_theme == 2 ? 15 : 0 }}
    >
      <div className="player-image">
        <img
          src={`https://api.thefootballbrain.app/uploads/players/${props.player.id}.jpg`}
          alt="player"
          onError={addDefaultSrc}
          className="player-profile-image"
          style={{ borderRadius: props.theme == 2 ? "15px 15px 0px 0px" : 0 }}
        />

        <div
          className="position-label"
          style={{
            borderRadius:
              props.config_options.id_theme == 2 ? "0px 15px 0px 0px" : 0,
            color: props.config_options.social_text_color,
            fill: props.config_options.main_color,
          }}
        >
          {props.player.position === "Goalkeeper" && (
            <svg
              className="position-label-img"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              version="1.1"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              viewBox="0 0 6995 6995"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <polygon points="5246,0 6995,0 6995,1749 6995,6995 5246,6995 5246,1749 1749,1749 1749,6995 0,6995 0,1749 0,0 1749,0 " />
              </g>
            </svg>
          )}
          {props.player.position === "Defender" && (
            <svg
              className="position-label-img"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              version="1.1"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              viewBox="0 0 6668 6666"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path d="M0 0l6668 0c0,3333 0,5000 -3334,6666 -3334,-1666 -3334,-3333 -3334,-6666z" />
              </g>
            </svg>
          )}
          {props.player.position === "Midfielder" && (
            <svg
              className="position-label-img"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              version="1.1"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              viewBox="0 0 6774 6560"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path d="M4234 0c1460,376 2540,1701 2540,3280 0,1579 -1080,2904 -2540,3280l0 -6560zm-1694 6560c-1461,-376 -2540,-1701 -2540,-3280 0,-1579 1079,-2904 2540,-3280l0 6560z" />
              </g>
            </svg>
          )}
          {props.player.position === "Forward" && (
            <svg
              className="position-label-img"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              version="1.1"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              viewBox="0 0 3874 5165"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <polygon points="0,2582 1937,0 3874,2583 2582,2583 2582,5165 1291,5165 1291,2582 " />
              </g>
            </svg>
          )}
          {props.player.position === "Goalkeeper" && "portar"}
          {props.player.position === "Defender" && "fundaș"}
          {props.player.position === "Midfielder" && "mijlocaș"}
          {props.player.position === "Forward" && "atacant"}
        </div>
      </div>
      <div className="player-details notranslate">
        <div
          className="player-number"
          style={{ color: props.config_options.buttons_color }}
        >
          {props.player.shirt_number > 0 ? props.player.shirt_number : "#"}
        </div>
        <div
          className="player-name"
          style={{ color: props.config_options.social_text_color }}
        >
          {props.player.name}
        </div>
      </div>
      {/* <div className="player-age-dob">11 ani</div> */}
      <div className="see-more-button-player">
        <a
          href={
            window.location.origin +
            `/player/${props.player.name
              // .replace(/ - | /g, "-")
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[^a-zA-Z0-9 \-]/g, "")
              .replaceAll(" ", "-")}-${props.player.id}`
          }
        >
          <Button
            className="arrow-button"
            // onClick={() => {
            //   history.push(`/player/${props.player.id}`)
            //   // console.log(props.player)
            // }}
            theme={props.config_options.id_theme}
            color={props.config_options.buttons_text_color}
            backgroundColor={props.config_options.buttons_color}
          >
            Vezi profil{" "}
            <ArrowRight
              fill={props.config_options.buttons_text_color}
              className="discover-more-button-icon"
            />
          </Button>
        </a>
      </div>
    </div>
  );
}
