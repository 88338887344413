import React from "react";
import "./News.scss";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/svg/arrow-left.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import NewsElement from "./NewsPreview";
import Loader from "react-loader-spinner";
import axios from "axios";
import { event } from "jquery";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      news: [],
      total_pages: "",
      loaded: false,
      pagination_group: [],
      actual_pagination_group_nr: 1,
      nr_pagination_groups: 0,
      pageLimit: 3,
    };
  }

  componentDidMount = () => {
    this.getNews();
    window.scrollTo({ behavior: "smooth", top: "0px" });
  };

  // componentDidUpdate = (prevState) => {
  //   if (prevState.activePage !== this.state.activePage) {
  //     // this.getNews();
  //   }
  // };

  getNews = () => {
    axios
      .get(
        `site/get_site_news?id_club=${this.props.id_club}&page=${this.state.activePage}`
      )
      .then((res) => {
        this.setState(
          {
            news: res.data.news,
            total_pages: res.data.total_pages,
            loaded: true,
          },
          () => {
            this.setState({
              nr_pagination_groups: Math.ceil(
                this.state.total_pages / this.state.pageLimit
              ),
            });
            this.getPaginationGroup();
          }
        );
      })
      .catch((err) => console.log(err));
  };

  changePage = (event) => {
    const pageNumber = parseInt(event.target.textContent);
    this.setState({ activePage: pageNumber, loaded: false }, () => {
      this.getNews();
    });
  };

  getPaginationGroup = () => {
    let groupPage = [];
    for (let i = 0; i < this.state.pageLimit; i++) {
      let page_num =
        this.state.pageLimit * (this.state.actual_pagination_group_nr - 1) + i;
      if (page_num < this.state.total_pages) {
        groupPage.push(page_num + 1);
      }
    }
    this.setState({
      pagination_group: groupPage,
    });
  };

  handlePageDown = () => {
    if (this.state.activePage > 1) {
      this.setState(
        { activePage: this.state.activePage - 1, loaded: false },
        () => {
          if (this.state.activePage + 1 == this.state.pagination_group[0]) {
            this.setState(
              {
                actual_pagination_group_nr:
                  this.state.actual_pagination_group_nr - 1,
              },
              () => {
                this.getPaginationGroup();
              }
            );
          }
          this.getNews();
        }
      );
    }
  };

  handlePageUp = () => {
    if (this.state.activePage < this.state.total_pages) {
      this.setState(
        { activePage: this.state.activePage + 1, loaded: false },
        () => {
          if (
            this.state.activePage >
            this.state.pageLimit * this.state.actual_pagination_group_nr
          ) {
            this.setState(
              {
                actual_pagination_group_nr:
                  this.state.actual_pagination_group_nr + 1,
              },
              () => {
                this.getPaginationGroup();
              }
            );
          }
          this.getNews();
        }
      );
    }
  };

  render() {
    return (
      <div className="news-page-3">
        <div
          className="news-page-title"
          style={{ color: this.props.config_options.section_title_color }}
        >
          Știri
        </div>
        {!this.state.loaded ? (
          <div className="loader-container-3">
            <Loader
              type="ThreeDots"
              color={this.props.config_options.main_color}
              height={80}
              width={80}
            />
          </div>
        ) : (
          <div className="news-container">
            {this.state.news.map((item) => {
              return (
                <NewsElement
                  article={item}
                  config_options={this.props.config_options}
                />
              );
            })}
          </div>
        )}
        <div className="pagination-container">
          {/* {this.state.total_pages === 1 && (
            <div
              className="page-box active-page page-number-button"
              style={{
                backgroundColor: this.props.config_options.pagination_bg_color,
                color: this.props.config_options.pagination_text_color,
                borderRadius:
                  this.props.config_options.id_theme == 2 ? "50%" : 0,
              }}
            >
              <div>1</div>
            </div>
          )}
          {this.state.total_pages === 2 && (
            <>
              <div
                className="page-box change-page-button"
                onClick={this.handlePageDown}
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <LeftArrowIcon
                  fill={this.props.config_options.buttons_text_color}
                  className="change-page-button-img"
                />
              </div>
              <div
                className={
                  this.state.activePage === 1
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() => this.setState({ activePage: 1 })}
              >
                <div>1</div>
              </div>
              <div
                className={
                  this.state.activePage === 2
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() => this.setState({ activePage: 2 })}
              >
                <div>2</div>
              </div>
              <div
                className="page-box change-page-button"
                onClick={this.handlePageUp}
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <RightArrowIcon
                  fill={this.props.config_options.buttons_text_color}
                  className="change-page-button-img"
                />
              </div>
            </>
          )}

          {this.state.total_pages === 3 && (
            <>
              <div
                className="page-box change-page-button"
                onClick={this.handlePageDown}
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <LeftArrowIcon
                  fill={this.props.config_options.buttons_text_color}
                  className="change-page-button-img"
                />
              </div>
              <div
                className={
                  this.state.activePage === 1
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() => this.setState({ activePage: 1 })}
              >
                <div>1</div>
              </div>
              <div
                className={
                  this.state.activePage === 2
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() => this.setState({ activePage: 2 })}
              >
                <div>2</div>
              </div>
              <div
                className={
                  this.state.activePage === 3
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() => this.setState({ activePage: 3 })}
              >
                <div>3</div>
              </div>
              <div
                className="page-box change-page-button"
                onClick={this.handlePageUp}
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <RightArrowIcon
                  fill={this.props.config_options.buttons_text_color}
                  className="change-page-button-img"
                />
              </div>
            </>
          )}
          {this.state.total_pages > 3 && (
            <>
              <div
                className="page-box change-page-button"
                onClick={this.handlePageDown}
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <LeftArrowIcon
                  fill={this.props.config_options.buttons_text_color}
                  className="change-page-button-img"
                />
              </div>
              <div
                className={
                  this.state.activePage == 1
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() => this.setState({ activePage: 1 })}
                // onClick={() => this.changePage}
              >
                {"1"}
              </div>
              <div
                className={
                  this.state.activePage != 1 &&
                  this.state.activePage != this.state.total_pages
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                onClick={
                  this.state.activePage == 1
                    ? () => this.handlePageUp()
                    : this.state.activePage == this.state.total_pages
                    ? () => this.handlePageDown()
                    : () => {}
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <div>
                  {this.state.activePage === 1
                    ? 2
                    : this.state.activePage === this.state.total_pages
                    ? this.state.total_pages - 1
                    : this.state.activePage}
                </div>
              </div>
              <div
                className="page-box page-number-button dots-mobile"
                style={{
                  color: this.props.config_options.pagination_bg_color,
                  backgroundColor:
                    this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <div>...</div>
              </div>
              <div
                className={
                  this.state.activePage === this.state.total_pages
                    ? "page-box active-page page-number-button"
                    : "page-box page-number-button"
                }
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
                onClick={() =>
                  this.setState({ activePage: this.state.total_pages })
                }
              >
                <div>{this.state.total_pages}</div>
              </div>
              <div
                className="page-box change-page-button"
                onClick={this.handlePageUp}
                style={{
                  backgroundColor:
                    this.props.config_options.pagination_bg_color,
                  color: this.props.config_options.pagination_text_color,
                  borderRadius:
                    this.props.config_options.id_theme == 2 ? "50%" : 0,
                }}
              >
                <RightArrowIcon
                  fill={this.props.config_options.buttons_text_color}
                  className="change-page-button-img"
                />
              </div>
            </>
          )} */}
          {/* {this.state.actual_pagination_group_nr >=
            this.state.nr_pagination_groups && ( */}
          {this.state.activePage > 1 && (
            <div
              className="page-box change-page-button"
              onClick={this.handlePageDown}
              style={{
                backgroundColor: this.props.config_options.pagination_bg_color,
                color: this.props.config_options.pagination_text_color,
                borderRadius: "50%",
              }}
            >
              <LeftArrowIcon
                fill={this.props.config_options.buttons_text_color}
                className="change-page-button-img"
              />
            </div>
          )}

          {this.state.pagination_group.map((item, index) => (
            <div
              key={index}
              onClick={this.changePage}
              className={
                item == this.state.activePage
                  ? "page-box active-page page-number-button"
                  : "page-box page-number-button"
              }
              style={{
                backgroundColor: this.props.config_options.pagination_bg_color,
                color: this.props.config_options.pagination_text_color,
                borderRadius: "50%",
              }}
            >
              <span>{item}</span>
            </div>
          ))}

          {/* {this.state.actual_pagination_group_nr <this.state.nr_pagination_groups  */}
          {this.state.activePage < this.state.total_pages && (
            <div
              className="page-box change-page-button"
              onClick={this.handlePageUp}
              style={{
                backgroundColor: this.props.config_options.pagination_bg_color,
                color: this.props.config_options.pagination_text_color,
                borderRadius: "50%",
              }}
            >
              <RightArrowIcon
                fill={this.props.config_options.buttons_text_color}
                className="change-page-button-img"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default News;
