import React from "react";
import Button from "../../reusable/Button/Button";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/svg/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/svg/arrow-left.svg";
import "./PlayerElementButton.scss";

const PlayerElementButton = (props) => {
  const link =
    props.player != ""
      ? window.location.origin +
        `/player/${props.player.player_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9 \-]/g, "")
          .replaceAll(" ", "-")}-${props.player.id_player}`
      : "";

  return (
    <>
      {props.player == "" ? null : (
        <a href={link} style={{ textDecoration: "none", color: "inherit" }}>
          <div
            className="player-element-container"
            style={{
              borderRadius: 15,
              justifyContent:
                props.arrowDirection == "next" ? "flex-end" : "flex-start",
              padding:
                props.arrowDirection == "next" ? " 0 0 0 10px" : "0 10px 0 0",
              cursor: "pointer",
            }}
          >
            {props.arrowDirection == "next" ? (
              <>
                <div
                  className="player-number"
                  style={{ color: props.config_options.buttons_color }}
                >
                  {props.player.shirt_number > 0
                    ? props.player.shirt_number
                    : "#"}
                </div>
                <div className="player-name">{props.player.player_name}</div>
                <div
                  className="arrow"
                  style={{
                    margin:
                      props.arrowDirection == "next"
                        ? "0 30px 0 40px"
                        : "0 40px 0 30px",
                  }}
                >
                  {props.arrowDirection == "next" ? (
                    <ArrowRightIcon />
                  ) : (
                    <ArrowLeftIcon />
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className="arrow"
                  style={{
                    margin:
                      props.arrowDirection == "next"
                        ? "0 30px 0 40px"
                        : "0 40px 0 30px",
                  }}
                >
                  {props.arrowDirection == "next" ? (
                    <ArrowRightIcon />
                  ) : (
                    <ArrowLeftIcon />
                  )}
                </div>
                <div
                  className="player-number"
                  style={{ color: props.config_options.buttons_color }}
                >
                  {props.player.shirt_number > 0
                    ? props.player.shirt_number
                    : "#"}
                </div>
                <div className="player-name">{props.player.player_name}</div>
              </>
            )}
          </div>
        </a>
      )}
    </>
  );
};

export default PlayerElementButton;
